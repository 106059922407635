(function ($) {
  // On enter always submits the closest button
  $.fn.keySubmit = function () {
    $(this).on('keydown', 'input, select', function (e) { // retrieve all inputs
      // $(this)
      // .keydown(function (e) { // bind keydown on all inputs
      if (e.keyCode == 13) { // enter was pressed
        const selector = ($(this).closest('fieldset').length > 0) ? 'fieldset' : 'div:not(div.select)'
        $(this).closest(selector).find(':submit').eq(0)
          .focus()
      }
    })
  }

  // For select-element that needs to be submitted on change. I.e. Hits on serach-result
  $.fn.submitOnChange = function () {
    $(this).on('change', function () {
      const selector = ($(this).closest('fieldset').length > 0) ? 'fieldset' : 'div:not(div.select)'
      $(this).closest(selector).find(':submit').eq(0)
        .click()
    })
    return this
  }
}(jQuery))
