<template>
  <div ref="root" v-start-with-html="slotContent" />
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'

export default {
  directives: {
    startWithHtml: {
      inserted(el, binding) {
        el.insertAdjacentHTML('afterbegin', binding.value)
      }
    }
  },
  props: {
    slotContent: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const root = ref(null)
    const isMobile = computed(() => context.parent.$mq == 'mobile')

    let isOpened = false

    const toggle = () => {
      isOpened = !isOpened
      if (isOpened) {
        root.value.classList.add('is-opened')
      } else {
        root.value.classList.remove('is-opened')
      }
    }

    onMounted(() => {
      const button = root.value.querySelector('.js-button')
      const items = root.value.querySelectorAll('.c-highlighted-studies__item')

      if ((isMobile.value && items.length < 4) || (!isMobile.value && items.length < 7)) {
        toggle()
      }

      if (button) {
        button.addEventListener('click', (e) => {
          e.preventDefault()
          toggle()
        })
      }
    })

    return {
      root
    }
  }
}
</script>
