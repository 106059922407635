const anchorScroll = () => {
  const hasStickyNavigation = document.querySelector('.c-sticky-navigation-wrapper')
  if (hasStickyNavigation) {
    return false
  }
  const hash = window.location.hash.slice(1)

  if (hash) {
    const container = document.querySelector(`[data-id="${hash}"]`)
    const header = document.querySelector('.c-header')

    if (container) {
      const topOffset = container.getBoundingClientRect().top + window.scrollY - header.offsetHeight
      window.scrollTo({
        top: topOffset,
        left: 0
      })
    }
  }
}

export default anchorScroll
