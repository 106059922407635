import { tns } from 'tiny-slider/src/tiny-slider'

const carousel = () => {
  const matchMedia = window.matchMedia('(max-width: 767px)')
  const containers = Array.from(document.querySelectorAll('.c-downloadable-materials-block'))
  const WRAP_BY = 4

  if (containers.length) {
    containers.forEach((container) => {
      const sliderContainer = container.querySelector('.c-downloadable-materials-block__list')
      const elements = Array.from(sliderContainer.children)

      if (elements.length > WRAP_BY) {
        const wrappersCount = Math.ceil(elements.length / WRAP_BY)
        const arr = [...Array(wrappersCount)]
        let slider = null
        sliderContainer.classList.add('is-ready')

        const wrapElements = (array, num) => {
          const parent = container.querySelector('.c-downloadable-materials-block__list')
          parent.innerHTML = ''
          array.forEach((item, index) => {
            const wrap = document.createElement('div')
            const startIndex = index * num
            const endIndex = startIndex + num
            for (let i = startIndex; i < endIndex; i++) {
              if (elements[i]) {
                wrap.appendChild(elements[i])
              }
            }
            parent.appendChild(wrap)
          })
        }

        const unwrapElements = () => {
          // losing reference after destroy, searching for it once again
          const box = container.querySelector('.c-downloadable-materials-block__list')
          box.innerHTML = ''
          elements.forEach((element) => {
            box.appendChild(element)
          })
        }

        const initSlider = () => {
          if (slider) {
            return
          }
          slider = tns({
            container: container.querySelector('.c-downloadable-materials-block__list'),
            controls: false,
            nav: true,
            items: 1,
            loop: false,
            preventScrollOnTouch: 'auto'
          })
        }

        const destroySlider = () => {
          if (slider) {
            slider.destroy()
            slider = null
          }
        }

        if (matchMedia.matches) {
          wrapElements(arr, WRAP_BY)
          initSlider()
        }

        matchMedia.onchange = (e) => {
          if (e.matches) {
            wrapElements(arr, WRAP_BY)
            setTimeout(() => {
              initSlider()
            }, 0)
          } else {
            destroySlider()
            setTimeout(() => {
              unwrapElements()
            }, 0)
          }
        }

      }
    })
  }
}

export default carousel
