(function ($) {
  window.vt = window.vt || {}

  vt.Accordion = (function () {
    function initialize() {
      const accordionLink = $('.js-accordion-link')

      accordionLink.on('click', function () {
        const link = $(this);
        const linkText = link.find('.js-accordion-link-text');
        const accordion = link.closest('.js-accordion');
        const accordionContent = accordion.find('.js-accordion-content');

        if (link.hasClass('active')) {
          linkText.text(linkText.data('text-original'));
        } else {
          linkText.data('text-original', linkText.text());
          linkText.text(linkText.data('text-swap'));
        }
        
        link.toggleClass('active');
        accordionContent.slideToggle(200);
      })
    }
    return {
      initialize
    }
  }())
}(jQuery))
