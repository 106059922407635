/* ---- video youtube ---- */
// This code loads the Youtube IFrame Player API code asynchronously.

export default new Promise((onYouTubeIframeAPIReady) => {
  const tag = document.createElement('script')
  const firstScriptTag = document.getElementsByTagName('script')[0]

  window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady

  tag.src = 'https://www.youtube.com/iframe_api'
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
})
