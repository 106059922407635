(function ($) {
  window.vt = window.vt || {}

  vt.RecommendedItem = (function () {
    function initialize(element) {
      $(element).on('click', (e) => {
        const recipeId = $(element).data('recipe-id')
        const recipeName = $(element).data('recipe-name')
        const serviceName = $(element).data('service-name')

        if (recipeId) {
          if (typeof dataLayer !== 'undefined') {
            e.preventDefault()
            dataLayer.push({
              event: 'Raptor Recommendation',
              'Recipe ID': recipeId,
              'Recipe Name': recipeName,
              'Raptor Recommendation Service': serviceName,
              eventCallback() {
                window.location = $(element).attr('href')
              },
              eventTimeout: 1000
            })
          }
        }
      })
    }

    return {
      initialize
    }
  }())
}(jQuery))
