(function ($) {
  window.vt = window.vt || {}

  vt.EpiBlogSubscriptionsAutoShowPopup = (function () {
    function initialize(element) {
      if ($(window).width() < 690 && $.cookie('epiblogmailinglist-subscribe') !== 'True') {
        setTimeout(() => {
          const popup = document.querySelector('.js-subscription-popup')
          if (popup) {
            popup.hidden = false
          }
        }, 20000)
      }
    }


    return {
      initialize
    }
  }())
}(jQuery))
