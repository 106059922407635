import axios from 'axios'
import settings from '@/Redesign/settings'
import { getLocale } from '@/Redesign/Helpers/locale'
import { keysFromCamelToPascalCase } from '@/Redesign/Helpers/keys'

axios.defaults.headers['Content-Type'] = 'application/json'
const locale = getLocale()

export default {
  blogSubscribe(data, token) {
    return axios.post(settings.apiUrls.blog.subscribe, data,
      {
        headers: {
          RecaptchaToken: token
        }
      })
  },
  getDownloadFormData() {
    const params = {
      language: locale
    }
    return axios.get(settings.apiUrls.downloadForm.getModel, { params })
  },
  submitDownloadForm(model, token) {
    const data = {
      ...keysFromCamelToPascalCase(model)
    }
    return axios.post(settings.apiUrls.downloadForm.submitForm, data,
      {
        headers: {
          RecaptchaToken: token
        }
      })
  },
  submitContactForm(model, token) {
    const data = {
      ...keysFromCamelToPascalCase(model)
    }
    return axios.post(settings.apiUrls.contactForm.submitForm, data,
      {
        headers: {
          RecaptchaToken: token
        }
      })
  },
  submitSignupForm(model, token) {
    const data = {
      ...keysFromCamelToPascalCase(model)
    }
    return axios.post(settings.apiUrls.signupForm.submitForm, data,
      {
        headers: {
          RecaptchaToken: token
        }
      })
  }
}
