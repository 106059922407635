(function ($) {
  window.vt = window.vt || {}

  vt.EpiBlogSubscriptionFormValidation = (function () {
    function initialize(element) {
      element.addEventListener('submit', validateForm, false)
    }

    function validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    }

    function validateForm(event) {
      // we reset the form state
      const emailError = document.querySelector('.js-subscriberform-email-error')
      emailError.hidden = true

      const mailingListError = document.querySelector('.js-subscriberform-mailinglist-error')
      mailingListError.hidden = true

      let formValid = true // everything is valid until it isn't

      const email = this.querySelector('input[name=emailBox]')
      const mailinglists = this.querySelectorAll('input[name=mailingListsBox]:checked')

      email.classList.remove('invalid')

      if (email.value === '' || !validateEmail(email.value)) {
        formValid = false
        emailError.hidden = false
        email.classList.add('invalid')
      }

      if (mailinglists.length === 0) {
        formValid = false
        mailingListError.hidden = false
      }

      if (!formValid) {
        event.preventDefault()
      }

      return formValid
    }

    return {
      initialize
    }
  }())
}(jQuery))
