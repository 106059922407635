<template>
  <div>
    <DownloadMaterialModal />
  </div>
</template>

<script>
import DownloadMaterialModal from '@/Redesign/Components/DownloadMaterial/DownloadMaterialModal.vue'
import useDownloadMaterial from '@/Redesign/Compositions/useDownloadMaterial'

export default {
  components: {
    DownloadMaterialModal
  },
  setup() {
    const { setCreated } = useDownloadMaterial()
    setCreated(true)
  }
}
</script>
