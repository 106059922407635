const responsiveVideo = () => {
  const videos = document.querySelectorAll('video.js-responsive-video')
  const isMobile = window.matchMedia('(max-width: 569px)').matches // taken from vue mq setup

  videos.forEach((video) => {
    const videoSrc = video.querySelector('source')
    if (isMobile && video.dataset.posterMobile) {
      video.poster = video.dataset.posterMobile
    } else {
      video.poster = video.dataset.posterDesktop
    }
    if (isMobile && videoSrc.dataset.mobileSrc) {
      video.src = videoSrc.dataset.mobileSrc
    } else {
      video.src = videoSrc.dataset.desktopSrc
    }
  })
}

export default responsiveVideo
