(function ($) {
  window.vt = window.vt || {}

  vt.EventPageRightSection = (function () {
    function initialize(element) {
      // we unwrap the .fixed-container and .right-section from the proper RightSection because the EventPageType already has them.
      // select element to unwrap
      const innerFixedContainer = element.querySelector('.fixed-container .fixed-container')
      if (innerFixedContainer) {
        // get the element's parent node
        var parent = innerFixedContainer.parentNode

        // move all children out of the element
        while (innerFixedContainer.firstChild) parent.insertBefore(innerFixedContainer.firstChild, innerFixedContainer)

        // remove the empty element
        parent.removeChild(innerFixedContainer)
      }

      const innerRight = element.querySelector('.fixed-container .right-section')
      if (innerRight) {
        // get the element's parent node
        var parent = innerRight.parentNode

        // move all children out of the element
        while (innerRight.firstChild) parent.insertBefore(innerRight.firstChild, innerRight)

        // remove the empty element
        parent.removeChild(innerRight)
      }
    }
    return {
      initialize
    }
  }())
}(jQuery))
