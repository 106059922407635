(function ($) {
  window.vt = window.vt || {}

  vt.Breadcrumbs = (function () {
    function initialize(element) {
      const $breadcrumbsList = $('.js-breadcrumbs-list')

      if (window.innerWidth < 691) {
        const $container = $('.js-breadcrumbs-container', element)
        const $listItems = $('.js-breadcrumbs-list > .breadcrumbs-item:not(:last-of-type), .js-breadcrumbs-list > span.breadcrumbs-divider', element)

        listItemsWidth = 0
        $listItems.each(function () {
          listItemsWidth += $(this).outerWidth(true)
        })

        if (listItemsWidth > $container.width() && !$breadcrumbsList.parents('.newsroom').length) {
          element.classList.add('closed')
          const opener = element.querySelector('.js-breadcrumbs-opener')
          opener.addEventListener('click', function openBreadcrumb() {
            element.classList.remove('closed')
            this.remove()
          })
        }
      }
      if ($breadcrumbsList.parents('.newsroom').length) {
        $breadcrumbsList.find('.breadcrumbs-item').each((index, item) => {
          if (window.innerWidth < 691) {
            if ($(item).text().trim().length > 20) {
              const trimmedText = `${$(item).text().trim().substr(0, 20)}...`
              $(item).text(trimmedText)
              $breadcrumbsList.scrollLeft(9999)
            }
          } else if ($(item).text().trim().length > 40) {
            const trimmedText = `${$(item).text().trim().substr(0, 40)}...`
            $(item).text(trimmedText)
          }
        })
      }
    }

    return {
      initialize
    }
  }())
}(jQuery))
