(function ($) {
  window.vt = window.vt || {}

  const rightSection = $('.right-section')
  const leftSection = $('.left-section')
  const leftSectionContent = leftSection.find('.article')
  const block = rightSection.find('.show-after-content-on-mobile')

  if (!block.length || !leftSectionContent.length) {
    return null
  }

  block.clone().appendTo(leftSectionContent)

}(jQuery))
