window.grunticon = function (e) {
  if (e && e.length === 3) {
    const t = window; const n = !(!t.document.createElementNS || !t.document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect || !document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1') || window.opera && navigator.userAgent.indexOf('Chrome') === -1); const o = function (o) {
      const r = t.document.createElement('link'); const
        a = t.document.getElementsByTagName('script')[0]; r.rel = 'stylesheet', r.href = e[o && n ? 0 : o ? 1 : 2], a.parentNode.insertBefore(r, a)
    }; const
      r = new t.Image(); r.onerror = function () { o(!1) }, r.onload = function () { o(r.width === 1 && r.height === 1) }, r.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
  }
}
const baseUrl = `${process.publicPath}WebCore/Images/grunticons/`
grunticon([`${baseUrl}icons.data.svg.css`, `${baseUrl}icons.data.png.css`, `${baseUrl}icons.data.fallback.css`])
