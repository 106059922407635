export default (element, callback = null) => {
  const handlerCallback = (event) => {
    const isClickInsideElement = element.contains(event.target)
    if (!isClickInsideElement) {
      if (callback) {
        callback()
      }
      document.removeEventListener('click', handlerCallback, false)
    }
  }

  const addClickAway = () => {
    document.addEventListener('click', handlerCallback, false)
  }

  return {
    addClickAway
  }
}
