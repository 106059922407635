(function ($) {
  /* Fix for applying custom design on the select-element */
  $.fn.customSelect = function (flexWidth) {
    // Fixes padding issue in IE
    $(this).each(function () {
      const custom = $(this)
      // custom select already applied return
      if (custom.parent().find('p').length > 0) {
        return
      }

      const text = custom.parent().prepend(`<p>${$(this).find(':selected').text()}</p>`)
      if (flexWidth) {
        custom.css({
          width: '100%'
        })
      } else {
        custom.css({
          width: `${text.outerWidth(true) + 30}px`
        })
      }
      custom.on('change', function () {
        $(this).parent().find('p').text($(this).find(':selected').text())
      })

      custom.on('mousedown', function () {
        $(this).addClass('no-focus')
      })

      custom.on('blur', function () {
        $(this).removeClass('no-focus')
      })
    })
    return this
  }

  /* Switching images (logo) to png if svg is not supported */
  $.fn.replaceSvgWithPng = function (forceReplace) {
    // var forceReplace is used if we need to force a switch
    return $(this).each(function () {
      const img = $(this)
      const supportsSvg = !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect

      if (!supportsSvg || forceReplace) {
        if (img.data('fallback-image')) {
          // replace svg with fallback-image
          img.prop('src', img.data('fallback-image'))
        } else {
          img.prop('src', img.prop('src').replace('.svg', '.png'))
        }
      }
    })
  }

  /* ---- End ---- */
}(jQuery))
