(function ($) {
  window.vt = window.vt || {}

  vt.ReadingTimeCalculation = (function () {
    function initialize(element) {
      const $element = $(element);
      
      const wordsPerMinute = $element.data('words-per-minute') || 200; // Average case.
      const article = $element.find('.js-article-content')
      const readTimeHolder = $element.find('.js-read-time-holder')
      const readTimeCounter = $element.find('.js-read-time-counter')

      const articleTextLength = article.text().split(' ').length;

      if (articleTextLength > 0) {
        const value = Math.ceil(articleTextLength / wordsPerMinute);
        readTimeCounter.text(value);
        readTimeHolder.removeClass('hidden');
      }      
    }
    return {
      initialize
    }
  }())
}(jQuery))
