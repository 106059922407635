// to top right away
if (window.location.hash) window.scroll(0, 0)
// void some browsers issue
// setTimeout(function () { scroll(0, 0); }, 1);

$(window).load(() => {
  // *only* if we have anchor on the url
  const { hash } = window.location
  if (hash && $(hash).length) {
    const widthThreshold = 1200
    const headerHeightMobile = $('.megamenu-navigation--mobile').height() || 68
    const blockofsetTop = $(hash).offset().top
    const scrollTop = window.innerWidth < widthThreshold ? `${blockofsetTop - headerHeightMobile}px` : `${blockofsetTop}px`

    // smooth scroll to the anchor id
    $('html, body').animate({
      scrollTop
    }, 1000, 'swing')
  }
})
