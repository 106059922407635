(function ($) {
  /* Collpasing and expanding */
  $.fn.generalCollapse = function (settings) {
    // configurable options
    const o = $.extend({
      createLink: false,
      hasLink: false,
      before: false,
      collapsed: '',
      toggleText: '',
      expandedOnInit: false
    }, settings)
    return $(this).each(function () {
      // return if functionality is already applied
      if ($(this).hasClass('expanded-link') || $(this).hasClass('collapsed-link')) {
        return
      }

      // define
      const collapsible = $(this)
      let collapsibleLink
      const collapsibleContent = collapsible.parent().find(o.collapsed)

      // modify markup & attributes
      if (!collapsible.is('a')) {
        if (o.createLink) {
          collapsibleLink = $(`<a href="#" class="collapsible-toggle">${o.toggleText}</a>`)
          if (o.before) {
            collapsibleLink.insertBefore(collapsible)
          } else {
            collapsibleLink.insertAfter(collapsible)
          }
        } else if (o.hasLink) {
          collapsibleLink = collapsible.next('a')
        } else {
          collapsibleLink = collapsible.wrapInner('<a href="#" />')
        }
      } else {
        collapsibleLink = collapsible
      }

      // events
      collapsibleLink
        .on('collapse', () => {
          collapsibleLink
            .removeClass('expanded-link')
            .addClass('collapsed-link')
          collapsibleContent
            .removeClass('expanded')
            .addClass('hidden')
            .prop('aria-hidden', true)
        })
        .on('expand', () => {
          collapsibleLink
            .removeClass('collapsed-link')
            .addClass('expanded-link')
          collapsibleContent
            .removeClass('hidden')
            .addClass('expanded')
            .prop('aria-hidden', false)
        })
        .click(function () {
          if (collapsibleContent.hasClass('expanded')) {
            $(this).trigger('collapse')
            return false
          }
          $(this).trigger('expand')
          return false
        })

      // trigger collapse/expand
      if (o.expandedOnInit) {
        collapsibleLink.trigger('expand')
      } else if (!collapsibleContent.hasClass('expanded')) {
        collapsibleLink.trigger('collapse')
      }
    })
  }
}(jQuery))
