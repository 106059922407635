import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import { reactive } from '@vue/composition-api'

Vue.use(VueCompositionApi)

const state = reactive({
  fileName: '',
  fileUrl: '',
  blockId: '',
  materialTitle: '',
  showModal: false,
  created: false
})

export default () => {
  const setCreated = () => {
    state.created = true
  }
  const setFileInfo = ({ name, url }) => {
    state.fileUrl = url || state.fileUrl
    state.fileName = name || state.fileName
  }
  const setShowModal = (newVal) => {
    state.showModal = newVal
  }

  const setMaterialTitle = ({ materialTitle }) => {
    state.materialTitle = materialTitle
  }

  const setBlockId = ({ blockId }) => {
    state.blockId = blockId
  }

  return {
    state,
    setCreated,
    setFileInfo,
    setShowModal,
    setMaterialTitle,
    setBlockId
  }
}
