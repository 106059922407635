import ytPromise from '@/WebCore/Js/Modules/youtube-api-loader'

(function ($) {
  // Fixes bug in porltet,
  // we can not get the Click event of a button in a Portlet
  // and there is no event info on what was clicked
  $.fn.slideNewsList = function () {
    const
      $mainBox = $('.press-contacts')
    const $clicklinks = $mainBox.find('a.clickbtn')
    const activeClass = 'active'
    $clicklinks.on('click', function () {
      $(this).toggleClass(activeClass)
      $clicklinks.not(this).removeClass(activeClass)
    })
  }
}(jQuery))

window.vt = window.vt || {}

vt.CopyLinkToClipboard = (function () {
  const timeout = 1500
  const activeClassName = 'text-is-copied'

  function initialize(element) {
    const $element = $(element)
    $element.on('click', (e) => {
      e.preventDefault()
      window.Clipboard.copy(window.location.href)
      $element.addClass(activeClassName)
      setTimeout(() => {
        $element.blur()
        $element.removeClass(activeClassName)
      }, timeout)
    })
  }

  return {
    initialize
  }
}())

window.Clipboard = (function (window, document, navigator) {
  let textArea
  let copy

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i)
  }

  function createTextArea(text) {
    textArea = document.createElement('textArea')
    textArea.value = text
    textArea.setAttribute('readonly', true)
    textArea.style.position = 'absolute'
    textArea.style.top = '-9999px'
    textArea.style.pointerEvents = 'none'
    document.body.appendChild(textArea)
  }

  function selectText() {
    let range
    let selection

    if (isOS()) {
      range = document.createRange()
      range.selectNodeContents(textArea)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
  }

  function copyToClipboard() {
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }

  copy = function (text) {
    createTextArea(text)
    selectText()
    copyToClipboard()
  }

  return {
    copy
  }
}(window, document, navigator))


vt.StickyInParent = (function () {
  function initialize(element) {
    const $element = $(element)
    const { parentNode } = element
    const $window = $(window)
    $window.on('resize scroll', () => {
      if (parentNode.getBoundingClientRect().top < $(window).height() / 2 - $element.height() / 2) {
        $element.addClass('is-sticky')
      } else {
        $element.removeClass('is-sticky')
      }

      if ($('.newsroom')[0].getBoundingClientRect().bottom - $element.height() <= $(window).height() / 2) {
        $element.addClass('at-bottom')
      } else {
        $element.removeClass('at-bottom')
      }
    })
    $element.find('>*').on('click', (e) => {
      dataLayer.push({
        shareType: $(e.currentTarget).data('socialNetwork'),
        event: 'shareContent'
      })
    })
    $('.newsroom__article-details a').on('click', () => {
      dataLayer.push({
        event: 'clickPressContact'
      })
    })
  }
  return {
    initialize
  }
}())

$.fn.isInViewport = function () {
  const elementTop = $(this).offset().top
  const elementBottom = elementTop + $(this).outerHeight()

  const viewportTop = $(window).scrollTop()
  const viewportBottom = viewportTop + $(window).height()

  return elementBottom > viewportTop && elementTop < viewportBottom
}

vt.InlineMedia = (function () {
  let youtubeReady = false
  let isAutoPlayStarted = false
  let isPlayClicked = false

  const loadYoutubeScript = function () {
    if (window.YT) {
      youtubeReady = true
      return
    }
    const tag = document.createElement('script')

    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    ytPromise.then(() => {
      youtubeReady = true
    })
  }

  loadYoutubeScript()

  const VideoBlock = (domNodeOption) => {
    let player
    let $container
    let played = false
    function onPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING && !played) {
        played = true
      }
      if (event.data === 0) {
        $container.removeClass('playing pause expanded').addClass('play-ended')
        dataLayer.push({
          videoName: $container.data('title'),
          videoType: $container.data('duration') > 30 ? 'Press-Play' : 'Auto-Play',
          event: 'videoEnded'
        })
      }
      if (event.data === 2) {
        $container.addClass('pause')
        dataLayer.push({
          videoName: $container.data('title'),
          event: 'videoPaused'
        })
      }
    }
    const onPlayerReady = function (event) {
      getDuration(event.target)
      getVideoTitle(event.target)
    }
    let getDuration = function (data) {
      const duration = data.getDuration()
      $container.attr('data-duration', duration)
    }
    let getVideoTitle = function (data) {
      const { title } = data.getVideoData()
      $container.attr('data-title', title)
    }
    const initPlayer = (id) => {
      player = new YT.Player(`video-${id}`, {
        host: 'https://www.youtube-nocookie.com',
        playerVars: {
          autoplay: 0,
          rel: 0,
          modestbranding: 1,
          controls: 0
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange
        },
        videoId: id
      })
    }

    const attachEvents = function () {
      $container.find('.js-play-button').on('click', (e) => {
        e.preventDefault()
        $container.addClass('playing').removeClass('pause')
        if ($container.data('duration') > 30) {
          $container.addClass('expanded')
          if (!$('.video-overlay').length) {
            $('body').append('<div class="video-overlay"></div>')
          }
          setTimeout(() => {
            player.playVideo()
            if (!isPlayClicked) {
              dataLayer.push({
                videoName: $container.data('title'),
                videoType: 'Press-Play',
                event: 'videoStarted'
              })
            }
            isPlayClicked = true
          }, 600)
        } else {
          player.playVideo()
          if (!isAutoPlayStarted) {
            dataLayer.push({
              videoName: $container.data('title'),
              videoType: 'Auto-play',
              event: 'videoStarted'
            })
          }
        }
      })
      $container.find('.js-pause-button').on('click', (e) => {
        e.preventDefault()
        player.pauseVideo()
        $container.addClass('pause')
      })
      $(document).on('click', '.video-overlay', () => {
        $container.each((index, item) => {
          if ($(item).hasClass('expanded')) {
            dataLayer.push({
              videoName: $('.expanded').data('title'),
              event: 'videoStopped'
            })
            $(item).removeClass('expanded playing pause')
            player.stopVideo()
            $('.video-overlay').remove()
            isPlayClicked = false
          }
        })
      })
    }

    const init = (domNode) => {
      if (!domNode) {
        return
      }
      $container = $(domNode)
      if ($container.data('youtube-video')) {
        return
      }
      function loadVideo() {
        var interval = setInterval(() => {
          if (youtubeReady) {
            initPlayer($container.data('id'))
            attachEvents()
            clearInterval(interval)
          }
        }, 300)
      }
      loadVideo()
    }

    init(domNodeOption)
  }

  function initialize() {
    $('.newsroom__content>iframe').each(function () {
      const videoId = $(this).attr('src').split('https://www.youtube-nocookie.com/embed/').pop()
      const videoCaption = $(this).next('p').find('em').text()
      if ($(this).next('p').find('em').length) {
        $(this).next('p').remove()
      }
      $(this).replaceWith(`<div class="newsroom__video js-video-block" data-id="${videoId}"> <div class="newsroom__video-inner"> <div id="video-${videoId}"></div> <picture class="newsroom__video-preview"> <source srcset="https://i.ytimg.com/vi/${videoId}/hqdefault.jpg" media="(min-width: 1140px)"> <source srcset="https://i.ytimg.com/vi/${videoId}/hqdefault.jpg" media="(min-width: 800px)"> <source srcset="https://i.ytimg.com/vi/${videoId}/hqdefault.jpg" media="(min-width: 640px)"> <img src="https://i.ytimg.com/vi/${videoId}/hqdefault.jpg"/> </picture> <button class="newsroom__video-btn newsroom__video-play js-play-button"><span><svg width="9" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M1.559 1.05l6.197 4.175a1 1 0 0 1-.008 1.664L1.55 10.977A1 1 0 0 1 0 10.142V1.88a1 1 0 0 1 1.559-.829z" fill="#999" fill-rule="evenodd"/></svg></span></button> <button class="newsroom__video-btn newsroom__video-pause js-pause-button"><span>pause video</span></button></div> <span class="newsroom__media-description">${videoCaption}</span></div>`)
    })
    $('.newsroom__content>p>img').each(function () {
      const imageSrc = $(this).attr('src')
      const imageCaption = $(this).parent().next('p').find('em')
        .text()
      if ($(this).parent().next('p').find('em').length) {
        $(this).parent().next('p').remove()
      }
      $(this).parent().replaceWith(`<div class="newsroom__media-content"> <picture class="newsroom__media-picture"> <source srcset="${imageSrc}" media="(min-width: 640px)"> <img src="${imageSrc}" /> </picture> <span class="newsroom__media-description">${imageCaption}</span> </div>`)
    })
    const $videoBlock = $('.js-video-block')
    $videoBlock.each(function () {
      const video = new VideoBlock(this)
    })
    $(window).on('resize scroll', () => {
      $videoBlock.each(function () {
        if ($(this).isInViewport() && $(this).data('duration') <= 30 && !$(this).hasClass('pause') && !$(this).hasClass('play-ended') && $(window).width() > 1024 && !isAutoPlayStarted) {
          $(this).find('.js-play-button').click()
          isAutoPlayStarted = true
        }
      })
    })
  }

  return {
    initialize
  }
}())
