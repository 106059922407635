<template>
  <ValidationObserver
    ref="observer"
    tag="form"
    class="form-deck c-form--default c-form--modern"
    slim
    :class="{ 'is-loading': loading }"
    @submit.prevent="onSubmit"
  >
    <div
      v-if="showSuccess"
      class="c-success-summary"
      v-html="model.successHtml"
    />
    <div class="c-form-content u-width-max u-ml--a u-mr--a">
      <h3 class="h1--md">{{ model.header }}</h3>
      <p class="u-font-size-dates">{{ model.description }}</p>
      <div class="c-form-column-container">
        <div class="c-form-column">
          <ValidationProvider
            v-if="!model.selectedIndustry"
            v-slot="{ errors, classes }"
            :name="model.industryLabel"
            rules="required"
            tag="div"
            class="c-form-row"
          >
            <label
              for="contact-form-industry"
              class="c-label--s"
              v-text="model.industryLabel"
            />
            <Dropdown
              id="contact-form-industry"
              v-model="formModel.industry"
              :class="classes"
              class="c-form-dropdown"
              :hint="model.industryHint"
              :items="{group: model.industries}"
            />
            <span
              v-if="errors.length"
              class="c-error-message u-text-error"
            >
                {{ errors[0] }}
              </span>
          </ValidationProvider>
          <ValidationProvider
              v-slot="{ errors, classes }"
              :name="model.firstNameLabel"
              rules="required"
              tag="div"
              class="c-form-row"
            >
              <label
                for="contact-form-firstName"
                class="c-label--s"
                v-text="model.firstNameLabel"
              />
              <input
                id="contact-form-firstName"
                v-model="formModel.firstName"
                :class="classes"
                :placeholder="model.firstNameHint"
                class="c-text-input"
                type="text"
              >
              <span
                v-if="errors.length"
                class="c-error-message u-text-error"
              >
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          <ValidationProvider
              v-slot="{ errors, classes }"
              :name="model.lastNameLabel"
              rules="required"
              tag="div"
              class="c-form-row"
            >
              <label
                for="contact-form-lastName"
                class="c-label--s"
                v-text="model.lastNameLabel"
              />
              <input
                id="contact-form-lastName"
                v-model="formModel.lastName"
                :class="classes"
                :placeholder="model.lastNameHint"
                class="c-text-input"
                type="text"
              >
              <span
                v-if="errors.length"
                class="c-error-message u-text-error"
              >
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          <ValidationProvider
              v-slot="{ errors, classes }"
              :name="model.emailLabel"
              rules="required|email"
              tag="div"
              class="c-form-row"
            >
              <label
                for="contact-form-email"
                class="c-label--s"
                v-text="model.emailLabel"
              />
              <input
                id="contact-form-email"
                v-model.lazy="formModel.email"
                :class="classes"
                :placeholder="model.emailHint"
                class="c-text-input"
                type="email"
              >
              <span
                v-if="errors.length"
                class="c-error-message u-text-error"
              >
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          <ValidationProvider
              v-slot="{ errors, classes }"
              :name="model.countryLabel"
              rules="required"
              tag="div"
              class="c-form-row"
            >
              <label
                for="contact-form-country"
                class="c-label--s"
                v-text="model.countryLabel"
              />
            <Dropdown
              id="contact-form-country"
              v-model="formModel.country"
              :class="classes"
              class="c-form-dropdown"
              :hint="model.countryHint"
              :items="{highlighted: model.highlightedCountries, main: model.countries}"
            />
              <span
                v-if="errors.length"
                class="c-error-message u-text-error"
              >
                {{ errors[0] }}
              </span>
            </ValidationProvider>
        </div>
        <div class="c-form-column">
          <ValidationProvider
              v-slot="{ errors, classes }"
              :name="model.messageLabel"
              rules="required"
              tag="div"
              class="c-form-row c-form--textarea"
            >
              <label
                for="contact-form-message"
                class="c-label--s"
                v-text="model.messageLabel"
              />
              <textarea
                id="contact-form-message"
                v-model.lazy="formModel.message"
                :class="classes"
                :placeholder="model.messageHint"
                :rows="20"
                class="c-text-area"
              />
              <span
                v-if="errors.length"
                class="c-error-message u-text-error"
              >
                {{ errors[0] }}
              </span>
          </ValidationProvider>
          <span class="c-form-recaptcha-text" v-html="model.recaptchaDisclaimerHtml" />
          <ValidationProvider
              v-slot="{ errors, classes }"
              rules="required"
              :name="model.privacyPolicyLabel"
              tag="label"
              class="c-form-row c-checkbox"
              for="contact-form-terms-of-use"
          >
            <input
              id="contact-form-terms-of-use"
              v-model="formModel.privacyPolicy"
              value="terms"
              :class="classes"
              class="c-checkbox__input"
              type="checkbox"
            >
            <span
              class="c-checkbox__label u-font-size-dates"
              v-html="model.termsHtml"
            />
            <span class="c-checkbox-icon" />
            <div
              v-if="errors.length"
              class="c-error-message u-text-error"
            >
              {{ errors[0] }}
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors, classes }"
            rules="required"
            :name="model.acceptSubscriptionLabel"
            tag="label"
            class="c-form-row c-checkbox"
            for="contact-form-newsletter"
          >
            <input
              id="contact-form-newsletter"
              v-model="formModel.acceptSubscription"
              value="subscription"
              :class="classes"
              class="c-checkbox__input"
              type="checkbox"
            >
            <span
              class="c-checkbox__label u-font-size-dates"
              v-html="model.subscriptionHtml"
            />
            <span class="c-checkbox-icon" />
            <div
              v-if="errors.length"
              class="c-error-message u-text-error"
            >
              {{ errors[0] }}
            </div>
          </ValidationProvider>
          <div class="c-form-row">
            <span
              class="c-disclaimer u-mt--l u-font-size-dates"
              v-html="model.disclaimerHtml"
            />

            <p
              v-if="errorMessage"
              class="u-text-error u-mt--l u-font-size-dates"
              v-text="errorMessage"
            />

            <div class="c-form-row align-right">
              <input
                type="submit"
                :value="model.sendButtonText"
                class="c-button"
              >
            </div>
          </div>
        </div>
      </div>

    </div>

    <CssLoader
      v-if="loading"
      class="css-loader"
    />

    <recaptcha
      ref="recaptchaform"
      :recaptcha-key="model.recaptchaKey"
      @submit="submitForm"
    />
  </ValidationObserver>
</template>

<script>
import { ref, onMounted, reactive, computed } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Recaptcha from '@/Redesign/Components/Recaptcha.vue'
import Dropdown from '@/Redesign/Components/Dropdown.vue'
import CssLoader from '@/Redesign/Components/CssLoader.vue'
import api from '@/Redesign/Lib/api'
import { getQueryParam, getPageUrl } from "@/Redesign/Helpers/url";

// TODO: Add proper translation for messages
extend('required', required)
extend('email', email)

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CssLoader,
    Recaptcha,
    Dropdown
  },
  props: {
    model: {
      type: Object,
      default() {
        return {
          header: '',
          industries: [],
          countries: [],

          firstNameHint: '',
          lastNameHint: '',
          emailHint: '',
          countryHint: '',
          industryHint: '',
          messageHint: '',

          firstNameLabel: '',
          lastNameLabel: '',
          emailLabel: '',
          countryLabel: '',
          industryLabel: '',
          messageLabel: '',
          privacyPolicyLabel: '',
          acceptSubscriptionLabel: '',

          selectedIndustry: null,
          permissionVersion: '',
          recaptchaKey: '',
          disclaimerHtml: '',
          subscriptionHtml: '',
          sendButtonText: '',
          termsHtml: '',
          successHtml: '',
          errorText: '',
          language: '',
          recaptchaDisclaimerHtml: ''
        }
      }
    }
  },
  setup(props) {
    const observer = ref(null)
    const formModel = reactive({
      firstName: null,
      lastName: null,
      email: null,
      country: '',
      industry: '',
      message: '',
      privacyPolicy: [],
      acceptSubscription: [],
      reference: getQueryParam('utm-source'),
      pageName: document.querySelector('meta[property="og:title"]').content,
      pageUrl: getPageUrl(),
      pageId: document.body.getAttribute('data-page-id')
    })
    const showSuccess = ref(false)
    const errorMessage = ref(null)
    const loading = ref(false)
    const recaptchaform = ref(null)
    let colHeight = null

    const onSubmit = async () => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        // ABORT!!
      } else {
        recaptchaform.value.verify()
      }
    }

    const submitForm = async (token) => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        return
      }

      errorMessage.value = null
      loading.value = true

      api.submitContactForm({
        ...formModel,
        industry: props.model.selectedIndustry?.length ? props.model.selectedIndustry : formModel.industry,
        acceptTerms: true,
        acceptSubscription: true,
        blockId: props.model.blockId,
        permissionVersion: props.model.permissionVersion,
        language: props.model.locale
      }, token)
        .then((response) => {
          if (response && response.data && response.data.errorMessage) {
            errorMessage.value = response.data.errorMessage
          } else {
            window.Tracker.track({
              event: 'contactFormSubmit'
            })

            showSuccess.value = true
          }
        })
        .catch(() => {
          errorMessage.value = props.model.errorText
        })
        .then(() => {
          loading.value = false
          recaptchaform.value.reset()
        })
    }

    onMounted(() => {
      setTimeout(() => {
        observer.value.reset() // FIX: IE11 invalid field on component init
      }, 0)
      colHeight = document.querySelectorAll('.c-form-column')[0].clientHeight
      document.querySelector('.c-form--textarea').style.height = `${colHeight}px`
    })

    return {
      observer,
      recaptchaform,
      formModel,
      showSuccess,
      onSubmit,
      submitForm,
      loading,
      errorMessage
    }
  }
}
</script>
