<template>
  <div>
    <input
      :id="model.elementGuid"
      type="hidden"
      class="Form__Element FormHidden FormHideInSummarized"
      data-f-type="hidden"
      :name="model.elementName"
      :value="submissionData"
    >
    <div
      class="c-disclaimer u-mt--l"
      v-html="model.permissionBlock.disclaimerHtml"
    />

    <div class="Form__Element">
      <label
        for="permission-terms-of-use"
        class="c-checkbox"
      >
        <input
          id="permission-terms-of-use"
          v-model="termsAccepted"
          name="termsAccepted"
          class="c-checkbox__input"
          type="checkbox"
        >
        <span
          class="c-checkbox__label"
          v-html="model.permissionBlock.termsHtml"
        />
        <span class="c-checkbox-icon" />
        <div
          v-if="errorMessage.length"
          class="Form__Element__ValidationError"
        >
          {{ errorMessage }}
        </div>
      </label>

      <label
        for="permission-newsletter"
        class="c-checkbox"
      >
        <input
          id="permission-newsletter"
          v-model="subscriptionAccepted"
          name="termsAccepted"
          class="c-checkbox__input"
          type="checkbox"
        >
        <span
          class="c-checkbox__label"
          v-html="model.permissionBlock.subscriptionHtml"
        />
        <span class="c-checkbox-icon" />
      </label>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from '@vue/composition-api'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const errorMessage = ref('')
    const termsAccepted = ref(false)
    const subscriptionAccepted = ref(false)
    const submissionData = computed(() => JSON.stringify({
      termsAccepted: termsAccepted.value,
      subscriptionAccepted: subscriptionAccepted.value,
      permissionVersion: props.model.permissionBlock.permissionVersion
    }))

    onMounted(() => {
      const forms = window.epi && window.epi.EPiServer && window.epi.EPiServer.Forms
      if (!forms) {
        return
      }

      $.extend(true, forms, {
        Validators: {
          'Modules.Features.FormElements.Components.PermissionElementBlockComponent.PermissionElementBlockValidator': (_, __, validator) => {
            const isValid = termsAccepted.value
            errorMessage.value = !isValid ? validator.model.message : ''

            return {
              isValid
            }
          }
        }
      })
    })
cd
    return {
      termsAccepted,
      subscriptionAccepted,
      submissionData,
      errorMessage
    }
  }
}
</script>
