/**
 Pollyfill for changing color of SVG images
 .svg image is replaced with xml format to make it possible change fill color with CSS
*/
$(() => {
  jQuery('img.svg').each(function () {
    const $img = jQuery(this)
    const imgID = $img.attr('id')
    const imgClass = $img.attr('class')
    const imgURL = $img.attr('src')

    jQuery.get(imgURL, (data) => {
      let $svg = jQuery(data).find('svg')

      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID)
      }

      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', `${imgClass} replaced-svg`)
      }

      $svg = $svg.removeAttr('xmlns:a')

      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', `0 0 ${$svg.attr('height')} ${$svg.attr('width')}`)
      }

      $img.replaceWith($svg)
    }, 'xml')
  })
})

/**
 Avoiding one word on the last line
 in search result items
*/
$(() => {
  function noMoreLonelyWords(selector, numWords) {
    const elems = document.querySelectorAll(selector)
    for (let i = 0; i < elems.length; ++i) {
      const textArray = elems[i].innerText.split(' ')
      const lastWords = textArray.splice(-numWords, numWords).join('&nbsp;')

      for (let j = 0; j < textArray.length - 1; ++j) {
        if (textArray[j].length <= 1) {
          textArray[j] = `${textArray[j]}&nbsp;${textArray.splice(j + 1, 1)}`
        }
      }

      const textMinusLastWords = textArray.join(' ')
      elems[i].innerHTML = `${textMinusLastWords} ${lastWords}`
    }
  }

  noMoreLonelyWords('.result-item figcaption:not(.recipe-tile__meta)', 2)
  noMoreLonelyWords('.product-list-products .product h2', 2)
});
