const pressCenterTracking = () => {
  const newsLinks = document.querySelectorAll('[data-track-link-click="true"]')
  const pagingLinks = document.querySelectorAll('[data-track-paging-click="true"]')
  const subscribeBtn = document.querySelector('.right-content .cta-link')
  const pressCenterTel = document.querySelectorAll('[data-block-name="Press contacts"] [href^="tel:"]')[0]
  const personArlaTel = document.querySelectorAll('[data-block-name="Press contacts"] [href^="tel:"]')[1]
  const pressCenterEmail = document.querySelectorAll('[data-block-name="Press contacts"] [href^="mailto:"]')[0]

  if (newsLinks.length) {
    newsLinks.forEach((link) => {
      link.addEventListener('click', () => {
        window.dataLayer.push({
          event: link.dataset.trackingEvent,
          pressReleaseLinkText: link.dataset.pressReleaseLinkText,
          pressReleaseDateText: link.dataset.pressReleaseDateText
        })
      })
    })
  }

  if (pagingLinks.length) {
    pagingLinks.forEach((link) => {
      link.addEventListener('click', () => {
        window.dataLayer.push({
          event: link.dataset.trackingEvent,
          navigationType: link.dataset.navigationType
        })
      })
    })
  }

  if (subscribeBtn) {
    subscribeBtn.addEventListener('click', () => {
      window.dataLayer.push({
        event: 'subscribeCTAClick'
      })
    })
  }

  if(pressCenterTel) {
    pressCenterTel.addEventListener('click', () => {
      window.dataLayer.push({
        event: 'phoneLinkClick',
        contactNameText: document.querySelectorAll('[data-block-name="Press contacts"] h3')[0].innerText
      })
    })
  }

  if (pressCenterEmail) {
    pressCenterEmail.addEventListener('click', () => {
      window.dataLayer.push({
        event: 'emailLinkClick',
        contactNameText: document.querySelectorAll('[data-block-name="Press contacts"] h3')[2].innerText
      })
    })
  }

  if (personArlaTel) {
    personArlaTel.addEventListener('click', () => {
      window.dataLayer.push({
        event: 'phoneLinkClick',
        contactNameText: document.querySelectorAll('[data-block-name="Press contacts"] h3')[2].innerText
      })
    })
  }
}

export default pressCenterTracking
