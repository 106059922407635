export default {
  apiUrls: {
    blog: {
      subscribe: '/api/blog/subscribe/'
    },
    downloadForm: {
      getModel: '/api/downloadform/form',
      submitForm: '/api/downloadform/submit'
    },
    contactForm: {
      submitForm: '/api/contactform/submit'
    },
    signupForm: {
      submitForm: '/api/signupform/submit'
    }
  }
}
