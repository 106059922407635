var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"c-main-menu u-hidden-print",class:{
    'is-opened-overlay': _vm.state.isOverlayVisible,
    'has-no-animation': _vm.state.isAnimated,
    'is-submenu-opened': _vm.state.isSubmenuOpened
  }},[_c('div',{staticClass:"c-main-menu__overlay",on:{"click":_vm.hideOpenedSubmenu}}),(_vm.state.isSubmenuOpened)?_c('strong',{staticClass:"c-main-menu__submenu-title u-flex u-flex-align-center u-flex-justify-center"},[_vm._v(" "+_vm._s(_vm.state.submenuTitle)+" ")]):_vm._e(),_c('ul',{ref:"nav",staticClass:"c-main-menu__list u-bare-list"},[_vm._l((_vm.state.items),function(item,index){return _c('li',_vm._g({key:item.title.text,staticClass:"c-main-menu__item",class:{
        'is-active-item': index == _vm.state.openIndex || item.isSubmenuOpened,
        'is-right': item.isRightAlign
      }},!_vm.isMobile ? { mouseenter: function () { return _vm.onMenuItemHover(item, index); }, mouseleave: function () { return _vm.onMenuItemLeave(item, index); } } : {}),[_c('a',_vm._b({staticClass:"c-main-menu__item-link",class:{
          'is-active': item.title.isActive
        },attrs:{"href":item.title.url}},'a',item.title.attributes,false),[_vm._v(" "+_vm._s(item.title.text)+" "),(item.items.length)?_c('span',{staticClass:"c-main-menu__item-link-icon",on:{"click":function($event){$event.preventDefault();return _vm.toggleSubmenu(item)}}},[_c('ArrowIcon',{staticClass:"icon",attrs:{"width":"16px","height":"16px","fill":"light-gray"}})],1):_vm._e()]),_c('collapse-transition',[(item.items.length)?_c('SubMenu',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.state.openIndex || item.isSubmenuOpened),expression:"index == state.openIndex || item.isSubmenuOpened"}],attrs:{"is-active":_vm.state.isSubmenuOpened && item.isSubmenuOpened,"item":item}}):_vm._e()],1)],1)}),(_vm.isMobile && !_vm.isBlog)?_vm._l((_vm.topItems),function(item){return _c('li',{key:item.title.text,staticClass:"c-main-menu__item"},[_c('a',_vm._b({staticClass:"c-main-menu__item-link",class:{
            'is-active': item.title.isActive
          },attrs:{"href":item.title.url,"title":item.title.text}},'a',item.title.attributes,false),[_vm._v(" "+_vm._s(item.title.text)+" ")])])}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }