const contentBlocks = document.querySelectorAll('.article-content .js-doubled-block')
const sidebarBlocks = document.querySelectorAll('.split-column-layout__right .js-doubled-block')
if (contentBlocks.length && sidebarBlocks.length) {
  contentBlocks.forEach((contentBlock) => {
    const similarSidebarBlock = [...sidebarBlocks]
      .find((sidebarBlock) => contentBlock.innerHTML === sidebarBlock.innerHTML)
    if (similarSidebarBlock) {
      similarSidebarBlock.classList.add('u-hidden@mobile-tablet')
      contentBlock.classList.add('u-hidden@desktop')
    }
  })
}
