<template>
  <div
    v-on-clickaway="hideQuickSearch"
    class="c-quick-search"
  >
    <form
      method="get"
      class="c-quick-search__form"
      :action="model.url"
    >
      <input
        id="quick-search"
        ref="searchField"
        class="c-quick-search-field"
        type="search"
        name="query"
        required
        autocomplete="off"
        :aria-label="model.inputPlaceholder"
        :placeholder="model.inputPlaceholder"
      >
      <button
        :title="model.title"
        class="c-quick-search__form-submit"
        tabindex="0"
      >
        <IconSearch class="icon c-icon-16" />
      </button>
      <button
        v-if="isMobile"
        class="c-button--close"
        :title="model.title"
        @click.prevent="hideQuickSearch"
      >
        <span class="line" />
        <span class="line" />
      </button>
    </form>
    <label
      v-if="isMobile"
      for="quick-search"
      role="button"
      tabindex="0"
      class="u-flex"
      :title="model.title"
      @click="openQuickSearch"
    >
      <IconSearch class="icon c-icon-16" />
    </label>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { ref, computed } from '@vue/composition-api'
import IconSearch from '@/Redesign/Assets/Icons/search-new.svg'
import useHeader from '@/Redesign/Compositions/useHeader'

export default {
  components: {
    IconSearch
  },
  mixins: [clickaway],
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const searchField = ref(null)
    const isMobile = computed(() => context.parent.$mq == 'mobile' || context.parent.$mq == 'tablet-x')
    const {
      openQuickSearch,
      hideQuickSearch
    } = useHeader(isMobile)

    return {
      searchField,
      isMobile,
      openQuickSearch,
      hideQuickSearch
    }
  }
}
</script>
