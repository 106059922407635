(function ($) {
  window.vt = window.vt || {}

  vt.EpiBlogSubscriptions = (function () {
    function initialize(element) {
      const show = document.querySelectorAll('.js-subscription-show')
      const popup = document.querySelectorAll('.js-subscription-popup')

      for (let i = 0; i < show.length; i++) {
        show[i].addEventListener('click', (e) => {
          e.preventDefault()
          popup[0].hidden = false
        })
      }

      const hide = document.querySelectorAll('.js-subscription-hide')

      for (let j = 0; j < hide.length; j++) {
        hide[j].addEventListener('click', () => {
          for (let p = 0; p < popup.length; p++) {
            popup[p].hidden = true
          }
        })
      }
    }
    return {
      initialize
    }
  }())
}(jQuery))
