const Tracker = () => {
  const track = (obj) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(obj)
  }

  const getInTouchButtons = document.querySelectorAll('.cta-link, .c-expert-block__cta')
  const stickyBubbleButton = document.querySelector('.sticky-bubble')

  getInTouchButtons.forEach((item) => {
    if (item.dataset.tracking === '') {
      item.addEventListener('click', () => {
        track({
          event: 'getInTouchCTA'
        })
      })
    }
  })
  if (stickyBubbleButton) {
    stickyBubbleButton.addEventListener('click', () => {
      track({
        event: 'floatingContactCTA'
      })
    })
  }
  if (typeof $$epiforms !== 'undefined') {
    $$epiforms('.EPiServerForms[data-f-metadata="contactform"]')
      .on('formsSubmitted', () => {
        $$epiforms('.EPiServerForms[data-f-metadata="contactform"] h2').hide()
        track({
          event: 'submitFormCTA'
        })
      })

    $$epiforms('.EPiServerForms[data-f-metadata="latamform"]')
      .on('formsSubmitted', () => {
        track({
          event: 'latamFormSubmit'
        })
      })
  }
  const trackClick = (selector, callback) => {
    Array.from(document.querySelectorAll(selector)).forEach((el) => {
      if (el) {
        el.addEventListener('click', (e) => {
          callback(el, e)
        })
      }
    })
  }

  const trackLink = (clickEvent, linkElement, gtmObject) => {
    clickEvent.preventDefault()
    track(gtmObject)
    if (linkElement && linkElement.href) {
      setTimeout(() => {
        document.location = linkElement.href
      }, 500)
    }
  }

  const trackGenericLink = (selector) => {
    trackClick(selector, (el, e) => {
      e.preventDefault()
      const gtmObject = {
        event: el.dataset.gtmTrigger
      }

      Object.entries(el.dataset).forEach(([key, value]) => {
        if (key !== 'gtmTrigger') {
          gtmObject[key] = value
        }
      })

      trackLink(e, el, gtmObject)
    })
  }
  trackGenericLink('[data-gtm-trigger]')
}

export default Tracker
