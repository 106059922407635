import { getLocale } from '@/Redesign/Helpers/locale'
import { localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import zh from 'vee-validate/dist/locale/zh_CN.json'
import es from 'vee-validate/dist/locale/es.json'
import pt from 'vee-validate/dist/locale/pt_BR.json'

import * as translations from '@/Redesign/Translations'

const locales = {
  en,
  zh,
  es,
  pt
}

const locale = getLocale()

// Install and activate the Sweden locale.
localize(locale, locales[locale])

// CUSTOM RULES
const requiredAtLeaseOneCheckbox = {
  validate: (value) => ({
    required: true,
    valid: value && value.length > 0
  }),
  computesRequired: true,
  message: translations[locale].validation.requiredAtLeaseOneCheckbox
}

const requiredBothCheckboxes = {
  validate: (value) => ({
    required: true,
    valid: value && value.length == 2
  }),
  computesRequired: true,
  message: translations[locale].validation.requiredBothCheckboxes
}

export { requiredAtLeaseOneCheckbox, requiredBothCheckboxes }
