<template>
  <modal
    :name="modalName"
    :adaptive="true"
    height="auto"
    width="950px"
    scrollable
    reset
    @closed="setShowModal(false)"
  >
    <div class="v-modal__inner">
      <DownloadMaterialForm
        v-if="formModel"
        :model="formModel"
      />
      <CssLoader
        v-else
        class="css-loader"
      />
      <span
        role="buttton"
        aria-label="Close popup"
        class="v-modal__button--close"
        @click="hideModal"
      />
    </div>
  </modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import DownloadMaterialForm from '@/Redesign/Components/DownloadMaterial/DownloadMaterialForm.vue'
import CssLoader from '@/Redesign/Components/CssLoader.vue'
import useDownloadMaterial from '@/Redesign/Compositions/useDownloadMaterial'
import api from '@/Redesign/Lib/api'
import { keysToCamelCase } from '@/Redesign/Helpers/keys'

export default {
  components: {
    CssLoader,
    DownloadMaterialForm
  },
  setup(_, context) {
    const modalName = 'download-form'
    const formModel = ref(null)
    const { $modal } = context.parent
    const { state, setShowModal } = useDownloadMaterial()

    const setUpModel = () => {
      api.getDownloadFormData()
        .then(({ data }) => {
          formModel.value = keysToCamelCase(data)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const showModal = () => {
      $modal.show(modalName)
    }

    const hideModal = () => {
      $modal.hide(modalName)
    }

    watch(
      () => state.showModal,
      (newVal) => {
        if (newVal) {
          showModal()
        } else {
          hideModal()
        }
      }
    )

    setUpModel()

    return {
      showModal,
      hideModal,
      modalName,
      setShowModal,
      formModel
    }
  }
}
</script>
