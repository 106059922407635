<template>
  <button
    class="c-main-menu__button"
    @click="toggleButton"
  >
    <span class="c-main-menu__button-inner">
      <span class="c-main-menu__button-line" />
      <span class="c-main-menu__button-line" />
      <span class="c-main-menu__button-line" />
    </span>
  </button>
</template>

<script>
import useHeader from '@/Redesign/Compositions/useHeader'

export default {
  setup() {
    const {
      toggleButton,
      state
    } = useHeader()

    return {
      state,
      toggleButton
    }
  }
}
</script>
