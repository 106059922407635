(function ($) {
  $(document).ready(() => {
    const $downloadModal = $('.download-material-modal')
    const $closeModalBtn = $downloadModal.find('.modal--close, .modal--overlay')
    $(document).on('click', '.download-material-button', (e) => {
      $downloadModal.removeClass('hidden')
    })

    $closeModalBtn.click(() => {
      $downloadModal.addClass('hidden')
    })

    const $summaryText = $downloadModal.find('.validation-summary-errors, .field-validation-error')

    if ($summaryText.length) {
      $downloadModal.removeClass('hidden')
    }

    // removing validation errors on change
    const $fields = $downloadModal.find("input[type='text']")
    const $checkbox = $downloadModal.find("input[type='checkbox']")
    const fieldError = 'input-validation-error'

    $fields.keyup(function () {
      $(this).removeClass(fieldError)
      $(this).next('.field-validation-error').remove()
    })

    $checkbox.change(function () {
      $(this).siblings('.field-validation-error').remove()
    })
  })
}(jQuery))
