<template>
  <select @blur="$emit('blur')" @change="$emit('input', $event.target.value)">
    <option
      value=""
      selected
      hidden
      v-text="hint"
    />
    <template v-for="(itemGroup, index) in itemKeys">
      <optgroup v-if="items[itemGroup]">
        <option
          v-for="item in items[itemGroup]"
          :key="item.value"
          :value="item.value"
          v-text="item.name"
        />
      </optgroup>
      <option v-if="items[itemGroup] && index < itemKeys.length - 1" value="-" disabled>------------------------------------------------</option>
    </template>
  </select>
</template>

<script>

import {ref} from "@vue/composition-api";

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      default: ''
    },
    items: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const itemKeys = ref(Object.keys(props.items))
    return {
      itemKeys
    }
  }
}
</script>
