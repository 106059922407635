import stickybits from 'stickybits'
import './subscription-form-validation'
import './subscription-popup'
import './subscriptions-autopop'
import './reading-time'
import './accordion'

if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
  stickybits('.blog-ribbon')
}
