const keysToLowerCase = (obj) => {
  const keys = Object.keys(obj)
  const newobj = {}

  keys.forEach((key) => {
    newobj[key.toLowerCase()] = obj[key]
  })

  return newobj
}

const keysToUpperCase = (obj) => {
  const keys = Object.keys(obj)
  const newobj = {}

  keys.forEach((key) => {
    newobj[key.toUpperCase()] = obj[key]
  })

  return newobj
}

const keysToCamelCase = (obj) => {
  const keys = Object.keys(obj)
  const newobj = {}

  keys.forEach((key) => {
    const newKey = key.replace(/^./, key[0].toLowerCase())
    newobj[newKey] = obj[key]
  })

  return newobj
}

const keysFromCamelToPascalCase = (obj) => {
  const keys = Object.keys(obj)
  const newobj = {}

  keys.forEach((key) => {
    const newKey = key.replace(/^./, key[0].toUpperCase())
    newobj[newKey] = obj[key]
  })

  return newobj
}

// eslint-disable-next-line import/prefer-default-export
export { keysToLowerCase, keysToUpperCase, keysToCamelCase, keysFromCamelToPascalCase }
