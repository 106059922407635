(function ($) {
  window.vt = window.vt || {}

  vt.RibbonMenu = (function () {
    function toggleScrollArrow(scroller, parent) {
      if (scroller.offsetWidth < scroller.scrollWidth) {
        // console.log("ribbon menu needs more space to grow");
        parent.classList.add('show-arrow')
      } else {
        // console.log("ribbon menu is content with the space it is being given");
        parent.classList.remove('show-arrow')
      }
    }

    function initialize(element) {
      const scroller = element.querySelector('.ribbon-menu-navigation-scroll')

      toggleScrollArrow(scroller, element)

      $(window).smartresize(() => {
        toggleScrollArrow(scroller, element)
      })
    }
    return {
      initialize
    }
  }())
}(jQuery))
