(function ($, _) {
  window.vt = window.vt || {}

  vt.FullWidthSlider = (function () {
    function initialize(element) {
      const isAFI = $('html').hasClass('afi')

      const configOptions = _.merge({
        // Default Options
        adaptiveHeight: true,
        mode: 'horizontal',
        pager: true,
        onSlideBefore($slideElement, oldIndex, newIndex) {
          const splitDeck = $slideElement.find('.split-deck')

          if (splitDeck.length) {
            if (window.matchMedia('(max-width: 640px)').matches) {
              $(element).parent().parent().find('.bx-prev, .bx-next')
                .css('top', splitDeck.find(':first-child').height())
            } else {
              $(element).parent().parent().find('.bx-prev, .bx-next')
                .css('top', '50%')
            }
          } else {
            $(element).parent().parent().find('.bx-prev, .bx-next')
              .css('top', '50%')
          }
        },
        onSliderLoad(currentIndex) {
          const splitDeck = $(element).find("> div:not([class='bx-clone'])").eq(0).find('.split-deck')

          if (splitDeck.length) {
            if (window.matchMedia('(max-width: 640px)').matches) {
              $(element).parent().parent().find('.bx-prev, .bx-next')
                .css('top', splitDeck.find(':first-child').height())
            }
          }
        }
      }, isAFI && {
        // AFI Options
        adaptiveHeight: false,
        preloadImages: 'visible',
        pager: true,
        pause: 7000,
        oneToOneTouch: false,
        preventDefaultSwipeX: false,
        preventDefaultSwipeY: true,
        wrapperClass: 'bx-wrapper autoslider'
      })

      const slider = $(element).bxSlider(configOptions)
      const sliderOffset = $(element).offset().top
      const viewPortSize = $(window).height()

      if (isAFI) {
        if (viewPortSize < sliderOffset - 200) {
          $(window).scroll(() => {
            slider.startAuto(true)
          })
        } else {
          slider.startAuto(true)
        }
      }
    }

    return {
      initialize
    }
  }())
}(jQuery, _))
