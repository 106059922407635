<template>
  <div
    ref="list"
    class="c-main-menu__dropdown"
  >
    <div class="c-main-menu__dropdown-inner u-flex u-flex-wrap">
      <div
        v-for="(section, i) in sections"
        :key="i"
        class="c-main-menu__dropdown-section"
        :class="`columns-${section.itemColumns.length}`"
      >
        <template v-if="section.title.url">
          <a
            :href="section.title.url"
            class="c-main-menu__section-title"
            v-text="section.title.text"
            v-bind="item.title.attributes"
            v-on:click="scrollToBlock"
          />
        </template>
        <template v-else>
          <span
            class="c-main-menu__section-title"
            v-text="section.title.text"
          />
        </template>
        <div class="c-main-menu__dropdown-section-columns">
          <ul
            v-for="(itemColumn, j) in section.itemColumns"
            :key="j"
            class="c-main-menu__dropdown-list u-bare-list"
            :class="{
              'no-heading': !section.title.text && anyTitle
            }"
          >
            <li
              v-for="link in itemColumn"
              :key="link.title.text"
            >
              <a
                class="c-main-menu__dropdown-link"
                :href="link.title.url"
                :class="{
                  'is-active': link.title.isActive
                }"
                v-bind="item.title.attributes"
                v-on:click="scrollToBlock"
              >
                {{ link.title.text }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- TODO: Add proper condition -->
      <div
        v-if="item && !isMobile && item.imageUrl"
        class="c-main-menu__dropdown-section c-main-menu__dropdown-section--image"
      >
        <a
          :href="item.title.url"
          :title="item.title.text"
          v-bind="item.title.attributes"
        >
          <img
            :src="item.imageUrl"
            :alt="item.title.text"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
const sectionItemsThreshold = 6
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sections() {
      const sections = []
      this.item.items.forEach((section) => {
        const columnCount = Math.ceil(section.items.length / sectionItemsThreshold)
        const newSection = { ...section, itemColumns: [] }

        for (let i = 0; i < columnCount; i++) {
          const start = i * sectionItemsThreshold
          const end = (i + 1) * sectionItemsThreshold
          if (columnCount > 1 && i == columnCount - 2 && newSection.items.length - end == 1) {
            newSection.itemColumns.push(newSection.items.slice(start, end - 1))
            newSection.itemColumns.push(newSection.items.slice(end - 1))
            break
          } else {
            newSection.itemColumns.push(newSection.items.slice(start, end))
          }
        }

        sections.push(newSection)
      })
      return sections
    },
    anyTitle() {
      return this.item.items.some((s) => s.title)
    },
    isMobile() {
      return this.$mq != 'desktop'
    }
  },
  methods: {
    scrollToBlock(e) {
      const href = e.currentTarget.href.split('#').pop()
      const section = document.querySelector(`[data-id="${href}"]`)

      if (section) {
        const topOffset = section.getBoundingClientRect().top + window.scrollY
        window.scrollTo({
          top: topOffset,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
}

</script>
