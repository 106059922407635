(function ($, _) {
  window.vt = window.vt || {}

  vt.FourColumnSlider = (function () {
    let debounceId
    let slider
    let sliderConfig
    let scrollTop

    function getItemWidth(element) {
      const measureElementClass = $(element).data('js-item-measure')
      const measureElement = $(`.${measureElementClass}`)
      return measureElement ? measureElement.width() : 200
    }

    function resizeSlider() {
      clearTimeout(debounceId)
      debounceId = undefined
      scrollTop = $(window).scrollTop()
      sliderConfig.slideWidth = getItemWidth(slider[0])
      slider.reloadSlider(sliderConfig)
    }

    function initialize(element) {
      const isAFI = $('html').hasClass('afi')
      scrollTop = $(window).scrollTop()

      sliderConfig = {
        useCSS: true,
        easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
        speed: 300,
        slideWidth: getItemWidth(element),
        minSlides: 2,
        maxSlides: 4,
        moveSlides: 3,
        pager: false,
        auto: false,
        onSliderLoad() {
          if (scrollTop > 0) {
            $(window).scrollTop(scrollTop)
          }
        },
        onSliderResize() {
          if (!debounceId) {
            debounceId = setTimeout(() => { resizeSlider() }, 500)
          }
        }
      }

      if (isAFI) {
        slider = $(element).bxSlider(sliderConfig)
      }
    }

    return {
      initialize
    }
  }())
}(jQuery, _))
