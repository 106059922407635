import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import { reactive } from '@vue/composition-api'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

Vue.use(VueCompositionApi)

const state = reactive({
  items: [],
  openIndex: -1,
  timer: null,
  isAnimated: false,
  animatedTimer: null,
  isOverlayVisible: false,
  isMobileOpened: false,
  isClosing: false,
  submenuTitle: '',
  isSubmenuOpened: false,
  isQuickSearchOpened: false,
  navRef: null
})

export default (isMobile = false) => {
  const setItems = (items) => {
    state.items = items
  }

  const onMenuItemHover = (item, index) => {
    if (isMobile.value) {
      return
    }
    if (state.timer) {
      clearTimeout(state.timer)
    }
    if (state.delayTimer) {
      clearTimeout(state.delayTimer)
    }
    if (item.items && item.items.length) {
      if (state.openIndex == -1) {
        state.delayTimer = setTimeout(() => {
          state.isOverlayVisible = true
          state.openIndex = index
        }, 200)
      } else {
        state.isOverlayVisible = true
        if (state.openIndex != -1) {
          state.isAnimated = true
        }
        state.openIndex = index
      }
    } else {
      state.openIndex = -1
      state.isOverlayVisible = false
    }
  }

  const onMenuItemLeave = () => {
    if (isMobile.value) {
      return
    }
    if (state.delayTimer) {
      clearTimeout(state.delayTimer)
    }
    state.timer = setTimeout(() => {
      state.isAnimated = false
      state.openIndex = -1
      state.isOverlayVisible = false
    }, 200)
  }

  const hideOpenedSubmenu = (submenu) => {
    const openedSubmenu = submenu || state.items.find((item) => item.isSubmenuOpened)
    if (openedSubmenu) {
      openedSubmenu.isSubmenuOpened = false
      state.isSubmenuOpened = false
    }
  }

  const openMobileMenu = () => {
    disablePageScroll(state.navRef)
    state.isMobileOpened = true
  }

  const closeMobileMenu = () => {
    enablePageScroll(state.navRef)
    state.isClosing = true
    state.isMobileOpened = false
    state.isSubmenuOpened = false
    setTimeout(() => {
      state.isClosing = false
      hideOpenedSubmenu()
    }, 300)
  }

  const toggleButton = () => {
    if (state.isMobileOpened) {
      closeMobileMenu()
    } else {
      openMobileMenu()
    }
  }

  const showSubmenu = (item) => {
    if (item.items.length && isMobile.value) {
      hideOpenedSubmenu()
      state.isSubmenuOpened = true
      item.isSubmenuOpened = true
      state.submenuTitle = item.title.text
    }
  }

  const backToMainMenu = () => {
    state.isSubmenuOpened = false
    hideOpenedSubmenu()
  }

  const documentClick = (e, $ref) => {
    if (state.isSubmenuOpened) {
      const el = $ref.value
      const { target } = e
      if (el !== target && !el.contains(target)) {
        state.isSubmenuOpened = false
        hideOpenedSubmenu()
      }
    }
  }

  const openQuickSearch = () => {
    state.isQuickSearchOpened = true
    closeMobileMenu()
  }

  const hideQuickSearch = () => {
    state.isQuickSearchOpened = false
  }

  const setNavigationRef = (ref) => {
    state.navRef = ref
  }

  return {
    state,
    setItems,
    setNavigationRef,
    onMenuItemHover,
    onMenuItemLeave,
    toggleButton,
    hideOpenedSubmenu,
    showSubmenu,
    backToMainMenu,
    documentClick,
    openQuickSearch,
    hideQuickSearch
  }
}
