(function ($) {
  /*
     * Used for tyhe hardcoded page specific disclaimer modals
     * that are then dismissed and not shown again to the user.
     */
  if ($('.modal')[0]) {
    const $modalsShowAlways = $('.modal:not([data-show-once])')
    const $modalsShowOnce = $('.modal[data-show-once]')

    // Show all $modalsShowAlways
    $modalsShowAlways.each(function () {
      const $el = $(this)
      $el.removeClass('hidden')
    })

    let modalsShownOnce = []
    const modalsShownString = $.cookie('modals_shown')
    if (typeof modalsShownString === 'undefined') {
      // Create modals_shown in cookie if it doesn't exist
      $.cookie('modals_shown', JSON.stringify(modalsShownOnce), {
        expires: 30
      })
    } else {
      // Otherwise parse it
      modalsShownOnce = JSON.parse(modalsShownString)
    }

    $modalsShowOnce.each(function () {
      const $el = $(this)
      const id = $el.data('id')

      if ($.inArray(id, modalsShownOnce) === -1) {
        // Show modal
        $el.removeClass('hidden')

        $el.find('.modal--close').click(() => {
          // Store id in cookie
          modalsShownOnce.push(id)
          $el.addClass('hidden')
          $.cookie('modals_shown', JSON.stringify(modalsShownOnce), {
            expires: 30
          })
        })
      }
    })
  }
}(jQuery))
