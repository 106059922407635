import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import { watch } from '@vue/composition-api'
import useHeader from '@/Redesign/Compositions/useHeader'
import useClickAway from '@/Redesign/Compositions/useClickAway'

Vue.use(VueCompositionApi)

const stickyHeader = () => {
  const {
    state
  } = useHeader()

  let timeout = null
  const header = document.querySelector('.c-header')
  const getScrollDistance = (function () {
    let prevScrollY = 0

    return function (scrollY) {
      const scrollDistance = scrollY - prevScrollY
      prevScrollY = scrollY
      return scrollDistance
    }
  }())

  const getScrollDirection = function (scrollDistance) {
    return scrollDistance >= 0 ? 'down' : 'up'
  }

  const setHeaderPos = function () {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      const distance = getScrollDistance(window.scrollY)
      const direction = getScrollDirection(distance)

      if (distance === 0) {
        return
      }


      if (direction === 'down' && !header.classList.contains('is-hidden') && window.scrollY >= 60) {
        header.classList.add('is-hidden')
      }

      if (direction === 'up' && header.classList.contains('is-hidden')) {
        header.classList.remove('is-hidden')
      }
    }, 20)
  }

  if (header) {
    window.addEventListener('scroll', setHeaderPos)

    watch(
      () => state.isMobileOpened,
      (isMobileOpened) => {
        header.classList[isMobileOpened ? 'add' : 'remove']('is-menu-opened')
      }
    )

    watch(
      () => state.isClosing,
      (isClosing) => {
        header.classList[isClosing ? 'add' : 'remove']('is-menu-closing')
      }
    )

    watch(
      () => state.isQuickSearchOpened,
      (isQuickSearchOpened) => {
        header.classList[isQuickSearchOpened ? 'add' : 'remove']('is-search-opened')
      }
    )
  }
}

const openClose = () => {
  const elements = document.querySelectorAll('.js-open-close')
  const activeClass = 'is-open'

  elements.forEach((element) => {
    const close = () => {
      element.classList.remove(activeClass)
    }
    const open = () => {
      element.classList.add(activeClass)
    }
    const { addClickAway } = useClickAway(element, close)

    const eventHandler = (element) => {
      if (element.classList.contains(activeClass)) {
        close(element)
      } else {
        open(element)
        addClickAway()
      }
    }

    element.addEventListener('click', () => {
      eventHandler(element)
    }, false)

    element.addEventListener('keyup', (event) => {
      if (event.target.getAttribute('role') == 'button' && event.keyCode == 13) {
        eventHandler(element)
      }
    }, false)
  })
}

export { openClose, stickyHeader }
