(function ($) {
  // TODO BETTER: Just redo this whole shit so we don't need to test for object fit or use mpg files.
  window.vt = window.vt || {}

  vt.DeckVideoBackground = (function () {
    function isElementVisible(element) {
      const rect = element.getBoundingClientRect()

      return (
        (rect.height > 0 || rect.width > 0)
                && rect.bottom >= 0
                && rect.right >= 0
                && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
                && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    function setupMP4(video, parent) {
      const visible = isElementVisible(parent)

      // set up play/pause on visible
      if (visible) {
        video.play()
      } else {
        video.pause()
      }

      window.addEventListener('scroll', () => {
        const scrollVisible = isElementVisible(parent)

        if (scrollVisible) {
          video.play()
        } else {
          video.pause()
        }
      })
    }

    function initialize(element) {
      setupMP4(element.querySelector('video'), element)
    }

    return {
      initialize
    }
  }())
}(jQuery))
