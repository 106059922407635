(function ($) {
  // Fixes bug in porltet,
  // we can not get the Click event of a button in a Portlet
  // and there is no event info on what was clicked
  $.fn.slideList = function () {
    const
      $mainBox = $('.list-items')
    const $clicklinks = $mainBox.find('a.clickbtn')
    const activeClass = 'active'
    $clicklinks.on('click', function () {
      $(this).toggleClass(activeClass)
      $clicklinks.not(this).removeClass(activeClass)
    })
  }
}(jQuery))
