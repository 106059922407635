import ytPromise from '@/WebCore/Js/Modules/youtube-api-loader'

(function ($) {
  /* ---- video youtube ---- */
  // This code loads the Youtube IFrame Player API code asynchronously.
  const youtubeApiTag = document.createElement('script')
  youtubeApiTag.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(youtubeApiTag, firstScriptTag)

  ytPromise.then(() => {
    $.fn.initVideoYoutube($('.portlet-video--youtube .video__container'))
    $('.portlet-video--youtube').parents('.split-deck .content-container, .generic-deck').addClass('video-c')
  })

  $.fn.initVideoYoutube = function (videoEls) {
    // Expand video to fit full width of the container
    function updateContainerHeight($iframeContainer, aspectRatio) {
      const blockWidth = $iframeContainer.width()
      const blockHeight = $iframeContainer.height()
      const blockAspect = blockHeight / blockWidth
      const viewportHeight = window.innerHeight
      let newVideoHeight = blockWidth * aspectRatio

      // Making sure that expanded video fits user's screen/window
      if (newVideoHeight > viewportHeight) {
        newVideoHeight = viewportHeight
      }

      // Making sure that expanded video fits even if left side has long content
      if (aspectRatio > blockAspect) {
        $iframeContainer.animate({
          height: newVideoHeight
        }, 600)
      }
    }

    function setContainerHeight(event, $iframeContainer) {
      const iframe = event.target.getIframe()
      const aspectRatio = iframe.height / iframe.width

      updateContainerHeight($iframeContainer, aspectRatio)

      $('html, body').animate({
        scrollTop: $iframeContainer.offset().top
      }, 500)

      $(window).on('resize', () => {
        const $videoPoster = $iframeContainer.find('.src-img')

        if (!$videoPoster.length) {
          updateContainerHeight($iframeContainer)
        }
      })
    }

    let isMobile = false
    let AUTOPLAY = 1
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
      isMobile = true
      AUTOPLAY = 0
    }

    videoEls.each(function (i) {
      const $thisEl = $(this)
      const playerId = `player-${i}`
      const videoId = $thisEl.attr('id')

      $thisEl.attr('id', `${videoId}-${i}`) // assigning unique ID for casese whene two identical videos are used on the same page

      function loadAndSetupYoutube() {
        // Create the player container
        const playerContainer = $('<div/>', {
          id: playerId
        })

        let SHOWINFO = 0
        if ($thisEl.hasClass('show-mobile-title') && isMobile) {
          SHOWINFO = 1
        }

        const posterHtml = $thisEl.html()

        // Print the playercontainer
        $thisEl.html(playerContainer).addClass('loaded')

        // This function creates an <iframe> (and YouTube player)
        // after the API code downloads.
        let player
        player = new YT.Player(playerId, {
          host: 'https://www.youtube-nocookie.com',
          videoId,
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange
          },
          playerVars: {
            autohide: 1,
            autoplay: AUTOPLAY,
            controls: 2,
            modestbranding: 1,
            showinfo: SHOWINFO,
            rel: 0
          }
        })

        // The Youtube Iframe API will call this function when the video player is ready.
        function onPlayerReady(event) {
          if (isMobile === true) {
            return
          }

          const $iframeContainer = $thisEl.closest('.content-block')
          setContainerHeight(event, $iframeContainer)
        }

        function onPlayerStateChange(event) {
          const $iframeContainer = $thisEl.closest('.content-block')

          if (isMobile && event.data == YT.PlayerState.BUFFERING) {
            setContainerHeight(event, $iframeContainer)
          }

          // Shrink the block to its initial height and show poster after video stops
          if (event.data == YT.PlayerState.ENDED) {
            if (!isMobile) {
              event.target.destroy()
            }

            const curHeight = $iframeContainer.height()

            $iframeContainer.css('height', 'auto')

            const autoHeight = $iframeContainer.height()

            $iframeContainer.height(curHeight).animate({
              height: autoHeight
            }, 400, () => {
              $iframeContainer.removeAttr('style')
            })

            if (!isMobile) {
              $thisEl.hide().html(posterHtml).fadeIn('slow')
            }
          }
        }
      }

      if (!$thisEl.hasClass('loaded')) {
        if (isMobile) {
          loadAndSetupYoutube()
        } else {
          $(document).delegate(`#${videoId}-${i}`, 'click', loadAndSetupYoutube)
        }
      }
    })
  }
}(jQuery))
