const stickyMenu = () => {
  let timeout = null
  const ribbonMenu = document.querySelector('.c-ribbon-menu-navigation')
  let selectedItem = null
  const getScrollDistance = (function () {
    let prevScrollY = 0

    return function (scrollY) {
      const scrollDistance = scrollY - prevScrollY
      prevScrollY = scrollY
      return scrollDistance
    }
  }())

  const getScrollDirection = function (scrollDistance) {
    return scrollDistance >= 0 ? 'down' : 'up'
  }

  const setMenuPos = function () {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      const distance = getScrollDistance(window.scrollY)
      const direction = getScrollDirection(distance)

      if (distance === 0) {
        return
      }

      if (direction === 'down' && ribbonMenu.classList.contains('is-sticky') && window.scrollY >= 60) {
        ribbonMenu.classList.remove('is-sticky')
      }

      if (direction === 'up' && !ribbonMenu.classList.contains('is-sticky')) {
        ribbonMenu.classList.add('is-sticky')
      }

    }, 20)
  }

  if (ribbonMenu) {
    selectedItem = ribbonMenu.querySelector('.selected')
    if (!selectedItem) {
      ribbonMenu.classList.add('no-selected-item')
    }
    window.addEventListener('scroll', setMenuPos)

    const ribbonMenuLinks = document.querySelectorAll('.c-ribbon-menu-link')
    ribbonMenuLinks.forEach((link) => {
      link.addEventListener('click', () => {
        const activeMainMenuItem = document.querySelector('.c-main-menu__item-link.is-active')
        if (activeMainMenuItem !== null) {
          window.dataLayer.push({
            event: 'ingredientsNavigationClick',
            industryName: activeMainMenuItem.innerText,
            ingredientName: link.innerText
          })
        }
        if (link.href.includes('sustainability')) {
          window.dataLayer.push({
            event: 'sustainabilityNavigationClick'
          })
        }
      })
    })
  }
}

const openCloseMenu = () => {
  const opener = document.querySelector('.c-ribbon-menu-selected-item')
  const ribbonMenu = document.querySelector('.c-ribbon-menu-navigation')

  const openerClick = function () {
    if (ribbonMenu.classList.contains('is-opened')) {
      ribbonMenu.classList.remove('is-opened')
    } else {
      ribbonMenu.classList.add('is-opened')
    }
  }

  if (ribbonMenu) {
    opener.addEventListener('click', openerClick)
    document.addEventListener('click', (event) => {
      if (ribbonMenu !== event.target && !ribbonMenu.contains(event.target)) {
        ribbonMenu.classList.remove('is-opened')
      }
    });
  }
}

export { stickyMenu, openCloseMenu }
