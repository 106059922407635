<template>
  <div ref="root" v-start-with-html="slotContent" />
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'

export default {
  directives: {
    startWithHtml: {
      inserted(el, binding) {
        el.insertAdjacentHTML('afterbegin', binding.value)
      }
    }
  },
  props: {
    slotContent: {
      type: String,
      default: ''
    }
  },
  setup() {
    const root = ref(null)
    let isOpened = false

    const toggle = () => {
      isOpened = !isOpened
      if (isOpened) {
        root.value.classList.add('is-opened')
      } else {
        root.value.classList.remove('is-opened')
      }
    }

    onMounted(() => {
      const button = root.value.querySelector('.js-button')
      if (!button) {
        return
      }
      button.addEventListener('click', (e) => {
        e.preventDefault()
        toggle()
      })
    })

    return {
      root
    }
  }
}
</script>
