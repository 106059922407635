(function ($) {
  $(document).ready(() => {
    const effectClass = 'action-button-hovered'

    $('.has-background-image')
      .not('link-hovered')
      .has('.action-link')
      .on('mouseenter', function () {
        $(this).addClass(effectClass)
      })
      .on('mouseleave', function () {
        $(this).removeClass(effectClass)
      })
  })
}(jQuery))
