<template>
  <nav
    class="c-main-menu u-hidden-print"
    :class="{
      'is-opened-overlay': state.isOverlayVisible,
      'has-no-animation': state.isAnimated,
      'is-submenu-opened': state.isSubmenuOpened
    }"
  >
    <div
      class="c-main-menu__overlay"
      @click="hideOpenedSubmenu"
    />
    <strong
      v-if="state.isSubmenuOpened"
      class="c-main-menu__submenu-title u-flex u-flex-align-center u-flex-justify-center"
    >
      {{ state.submenuTitle }}
    </strong>

    <ul
      ref="nav"
      class="c-main-menu__list u-bare-list"
    >
      <li
        v-for="(item, index) in state.items"
        :key="item.title.text"
        class="c-main-menu__item"
        :class="{
          'is-active-item': index == state.openIndex || item.isSubmenuOpened,
          'is-right': item.isRightAlign
        }"
        v-on="!isMobile ? { mouseenter: () => onMenuItemHover(item, index), mouseleave: () => onMenuItemLeave(item, index) } : {}"
      >
        <a
          class="c-main-menu__item-link"
          :href="item.title.url"
          :class="{
            'is-active': item.title.isActive
          }"
          v-bind="item.title.attributes"
        >
          {{ item.title.text }}
          <span
            v-if="item.items.length"
            class="c-main-menu__item-link-icon"
            @click.prevent="toggleSubmenu(item)"
          >
            <ArrowIcon
              class="icon"
              width="16px"
              height="16px"
              fill="light-gray"
            />
          </span>
        </a>

        <collapse-transition>
          <SubMenu
            v-if="item.items.length"
            v-show="index == state.openIndex || item.isSubmenuOpened"
            :is-active="state.isSubmenuOpened && item.isSubmenuOpened"
            :item="item"
          />
        </collapse-transition>
      </li>
      <template
        v-if="isMobile && !isBlog"
      >
        <li
          v-for="(item) in topItems"
          :key="item.title.text"
          class="c-main-menu__item"
        >
          <a
            class="c-main-menu__item-link"
            :href="item.title.url"
            :title="item.title.text"
            :class="{
              'is-active': item.title.isActive
            }"
            v-bind="item.title.attributes"
          >
            {{ item.title.text }}
          </a>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import { onMounted, onUnmounted, computed, ref } from '@vue/composition-api'
import SubMenu from '@/Redesign/Components/MainMenuSubmenu.vue'
import ArrowIcon from '@/Redesign/Assets/Icons/arrow_down--no-color.svg'
import useHeader from '@/Redesign/Compositions/useHeader'

export default {
  components: {
    ArrowIcon,
    SubMenu,
    CollapseTransition
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    topItems: {
      type: Array,
      default: () => []
    },
    isBlog: {
      type: Boolean,
      required: true
    }
  },
  setup(props, context) {
    const nav = ref(null)
    const isMobile = computed(() => context.parent.$mq != 'desktop')
    const { items } = props
    const {
      state,
      setItems,
      onMenuItemHover,
      onMenuItemLeave,
      hideOpenedSubmenu,
      showSubmenu,
      backToMainMenu,
      documentClick,
      setNavigationRef
    } = useHeader(isMobile)

    setItems(items)
    const toggleSubmenu = (item) => {
      if (!item.isSubmenuOpened) {
        showSubmenu(item)
      } else {
        hideOpenedSubmenu(item)
      }
    }

    onMounted(() => {
      setNavigationRef(nav.value)
      document.addEventListener('click', (e) => {
        documentClick(e, nav)
      })
    })
    onUnmounted(() => {
      document.removeEventListener('click', (e) => {
        documentClick(e, nav)
      })
    })

    return {
      nav,
      isMobile,
      state,
      onMenuItemHover,
      onMenuItemLeave,
      hideOpenedSubmenu,
      showSubmenu,
      backToMainMenu,
      documentClick,
      toggleSubmenu
    }
  }
}
</script>
