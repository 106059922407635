<template>
  <div ref="container">
    <div
      class="c-sticky-navigation u-flex u-flex-justify-center"
      :class="{
        'is-header-hidden': isHeaderHidden
      }"
      :style="{
        top: stickyBarOffset
      }"
    >
      <div
        ref="scroller"
        class="c-sticky-navigation__list-scroller"
        :class="{
          'u-width-100 c-sticky-navigation__list-scroller--no-scroll': sections.length < 5 && $mq != 'desktop'
        }"
      >
        <div class="c-sticky-navigation__list-wrap">
          <ul
            class="c-sticky-navigation__list u-flex u-bare-list"
            :class="{
              'u-flex-justify-evenly': sections.length < 5 && $mq != 'desktop'
            }"
          >
            <li
              v-for="(item, index) in sections"
              :key="item.anchorTag"
              class="c-sticky-navigation__list-item"
              :class="{
                'u-mr--s': sections.length > 4 && $mq != 'desktop'
              }"
            >
              <a
                :href="`#${item.anchorTag}`"
                class="u-flex u-flex-direction-column"
                :class="{
                  'is-active': index == activeIndex
                }"
                @click.prevent="scrollToBlock(item)"
              >
                <span class="c-sticky-navigation__list-item-icon u-ml--a u-mr--a">
                  <img
                    v-if="item.icon"
                    :src="item.icon"
                  >
                </span>
                {{ isNotDesktop ? item.mobileAnchorTitle : item.anchorTitle }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, onUnmounted, ref
} from '@vue/composition-api'

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup(props, context) {
    const isNotDesktop = computed(() => context.parent.$mq == 'mobile' || context.parent.$mq == 'tablet-x')
    const activeIndex = ref(-1)
    const stickyBarOffset = ref('auto')
    const header = document.querySelector('.c-header')

    let preventUpdateDuringScroll = false
    const isHeaderHidden = ref(header.classList.contains('is-hidden'))

    const watchHeader = () => {
      const config = { attributes: true }

      const callback = function (mutations) {
        mutations.forEach((mutation) => {
          const { target } = mutation

          if (mutation.attributeName === 'class') {
            isHeaderHidden.value = target.classList.contains('is-hidden')
          }
        })
      }
      const observer = new MutationObserver(callback)
      observer.observe(header, config)
    }

    // Create an observer instance linked to the callback function

    const calculateOffset = () => {
      const isDesktop = !isNotDesktop.value
      const headerHeight = header.offsetHeight

      if (isDesktop && context.refs.container.getBoundingClientRect().top < 100) {
        stickyBarOffset.value = `${headerHeight}px`
      } else {
        stickyBarOffset.value = '150px'
      }
    }

    const scrollToBlock = (item, instantScroll = false) => {
      const element = document.querySelector(`[data-id="${item.anchorTag}"]`)

      if (element) {
        const topOffset = element.getBoundingClientRect().top + window.scrollY - header.offsetHeight
        preventUpdateDuringScroll = true
        window.scrollTo({
          top: topOffset,
          left: 0,
          behavior: instantScroll ? 'auto' : 'smooth'
        })
        setTimeout(() => {
          preventUpdateDuringScroll = false
        }, 200)
      }
    }

    const sections = props.items.map(({
      anchorTag, icon, anchorTitle, mobileAnchorTitle
    }) => ({
      element: document.querySelector(`[data-id="${anchorTag}"]`),
      anchorTag,
      icon,
      anchorTitle,
      mobileAnchorTitle
    })).filter(({ anchorTag, anchorTitle, element }) => anchorTag && anchorTitle && element)

    const offset = computed(() => {
      if (context.parent.$mq == 'desktop') {
        return 250
      }
      return 200
    })

    const updateIndex = (item) => {
      if (item.index) {
        activeIndex.value = item.index
      } else {
        activeIndex.value = sections.findIndex(({ anchorTag }) => anchorTag == item.anchorTag)
      }
      window.location.hash = `#${item.anchorTag}`
    }

    const updateActiveIndexOnScroll = () => {
      if (!preventUpdateDuringScroll) {
        const offsets = sections.map(({ element, anchorTag }, index) => {
          const { y } = element.getBoundingClientRect()

          return {
            y, anchorTag, index
          }
        })
          .filter((item) => item.y <= offset.value)
          .sort((a, b) => a.y - b.y)

        if (offsets.length) {
          const activeAnchor = offsets[offsets.length - 1]
          updateIndex(activeAnchor)
          setTimeout(() => {
            const activeLink = context.refs.container.querySelector('.is-active')
            if (activeLink) {
              activeLink.scrollIntoViewIfNeeded()
            }
          }, 0)
        } else {
          activeIndex.value = -1
          window.location.hash = '#'
        }
      }
    }

    const scrollOffset = 20

    const showShadowsIfNeeded = () => {
      const { scroller, container } = context.refs
      const checkScroll = () => {
        if (!scroller || !container) {
          return
        }
        const { scrollLeft } = scroller
        if (scrollLeft > scrollOffset) {
          container.classList.add('has-left-shadow')
        } else {
          container.classList.remove('has-left-shadow')
        }
        if (scroller.scrollWidth - (scrollLeft + scroller.offsetWidth) < scrollOffset) {
          container.classList.remove('has-right-shadow')
        } else {
          container.classList.add('has-right-shadow')
        }
      }
      checkScroll()
      scroller.addEventListener('scroll', checkScroll)
    }

    onMounted(() => {
      window.addEventListener('scroll', calculateOffset)
      calculateOffset()
      if (window.location.hash) {
        const hash = window.location.hash.substring(1)
        const activeItem = props.items.find(({ anchorTag }) => anchorTag == hash)
        if (activeItem) {
          scrollToBlock(activeItem, true)
          updateIndex(activeItem)
        }
      }
      window.addEventListener('scroll', updateActiveIndexOnScroll)
      updateActiveIndexOnScroll()
      watchHeader()
      showShadowsIfNeeded()
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', updateActiveIndexOnScroll)
      window.removeEventListener('scroll', calculateOffset)
    })

    return {
      isNotDesktop,
      activeIndex,
      stickyBarOffset,
      scrollToBlock,
      sections,
      isHeaderHidden
    }
  }
}
</script>
