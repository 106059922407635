import Vue from 'vue'
import DownloadMaterialBlock from '@/Redesign/Components/DownloadMaterial/DownloadMaterialBlock.vue'
import useDownloadMaterial from '@/Redesign/Compositions/useDownloadMaterial'
import { getQueryParam, setSearchParam } from '@/Redesign/Helpers/url'

export default () => {
  const donwloadLinks = document.querySelectorAll('.js-download-form')
  const {
    state,
    setFileInfo,
    setShowModal,
    setMaterialTitle,
    setBlockId
  } = useDownloadMaterial()

  if (!donwloadLinks.length) {
    return
  }

  const downloadParam = getQueryParam('downloadUrl')
  donwloadLinks.forEach((link) => {
    /*
      There is two lftracker eventListeners that copies the event and dispatch them
      together with original one. In order to fix it, all listeners need to be removed.
    */
    const fileUrl = link.getAttribute('data-file-url')
    const fileName = link.getAttribute('data-title')
    const blockId = link.getAttribute('data-block-id')
    const materialTitle = link.querySelector('.js-download-form-title')?.innerText
    const linkCloneWithoutEventListeners = link.cloneNode(true)
    link.parentNode.replaceChild(linkCloneWithoutEventListeners, link)

    linkCloneWithoutEventListeners.addEventListener('click', (e) => {
      e.preventDefault()
      if (!state.created) {
        const DownloadMaterialBlockClass = Vue.extend(DownloadMaterialBlock)
        const formInstanse = new DownloadMaterialBlockClass()
        formInstanse.$mount()
        document.body.appendChild(formInstanse.$el)
      }
      setFileInfo({
        name: fileName,
        url: fileUrl
      })
      setMaterialTitle({ materialTitle })
      setBlockId({ blockId })
      const newUrl = setSearchParam('downloadUrl', fileUrl)
      window.history.replaceState({}, document.title, newUrl)
      setShowModal(true)
    }, false)

    if (downloadParam.indexOf(fileUrl) != -1) {
      linkCloneWithoutEventListeners.click()
    }
  })
}
