import { tns } from 'tiny-slider/src/tiny-slider'

const mediaQuery = window.matchMedia('(min-width: 1024px)')
const mediaisMobile = window.matchMedia('(min-width: 768px)')

const trackCarouselSwipe = (slider, eventName, index) => {
  let currentIndex = index || 1
  const trackSwipe = function (info) {
    let displayIndex = info.displayIndex
    if (currentIndex < displayIndex) {
      window.Tracker.track({
        event: eventName,
        direction: 'right'
      })
      currentIndex = displayIndex
    } else {
      window.Tracker.track({
        event: eventName,
        direction: 'left'
      })
      currentIndex = displayIndex
    }
  }
  if (slider) {
    slider.events.on('touchEnd', trackSwipe)
  }
}

const iconCarousel = () => {
  const sliderEl = document.querySelectorAll('.c-icon__slider')
  if (!sliderEl) {
    return
  }
  sliderEl.forEach((sliderItem) => {
    const slidesAmount = sliderItem.children.length
    const slider = tns({
      container: sliderItem,
      controls: false,
      nav: false,
      items: 1,
      loop: false,
      edgePadding: 60,
      startIndex: 1,
      preventScrollOnTouch: 'auto',
      responsive: {
        768: {
          items: slidesAmount,
          edgePadding: 0,
          startIndex: 0
        }
      }
    })
    if (sliderItem.children.length) {
      trackCarouselSwipe(slider, 'industryIconsSwipe', 1)
    }
  })
}

const featuredContentCarousel = () => {
  const sliderEl = Array.from(document.querySelectorAll('.c-featured-content__items'))

  sliderEl.forEach((sliderItem) => {
    let slider
    const sliderItemLength = sliderItem.children.length
    const options = {
      container: sliderItem,
      controls: false,
      nav: true,
      items: 1.3,
      loop: true,
      edgePadding: 20,
      preventScrollOnTouch: 'auto',
      gutter: 20,
      responsive: {
        768: {
          items: 3.5,
          edgePadding: 20,
          startIndex: 0,
          mouseDrag: true,
          controls: false,
          gutter: 20
        },
        1025: {
          items: 4.5,
          edgePadding: 0,
          startIndex: 0,
          mouseDrag: true,
          controls: true,
          gutter: 40
        }
      }
    }

    function handleScreenChange(e) {
      if (e.matches) {
        if (slider?.destroy) {
          slider.destroy()
        }
        if (sliderItemLength < 5) {
          return
        }

        if (!slider) {
          slider = tns(options)
          trackCarouselSwipe(slider, 'eventsCarouselSwipe', 1)
        } else if (!slider.isOn) {
          slider = slider.rebuild()
        }
      }
    }
    function handleIsMobileChange(e) {
      if (e.matches) {
        if (slider?.destroy) {
          slider.destroy()
        }
        if (sliderItemLength < 4) {
          return
        }

        if (!slider) {
          slider = tns(options)
          trackCarouselSwipe(slider, 'eventsCarouselSwipe', 1)
        } else if (!slider.isOn) {
          slider = slider.rebuild()
        }
      } else {
        if (slider?.destroy) {
          slider.destroy()
        }
        if (!slider) {
          slider = tns(options)
          trackCarouselSwipe(slider, 'eventsCarouselSwipe', 1)
        } else if (!slider.isOn) {
          slider = slider.rebuild()
        }
      }
    }
    mediaQuery.addEventListener('change', handleScreenChange)
    handleScreenChange(mediaQuery)
    mediaisMobile.addEventListener('change', handleIsMobileChange)
    handleIsMobileChange(mediaisMobile)
  })
}

const relatedContentCarousel = () => {
  const sliderEl = Array.from(document.querySelectorAll('.c-related-content__items'))

  sliderEl.forEach((sliderItem) => {
    let slider
    const sliderItemLength = sliderItem.children.length
    const options = {
      container: sliderItem,
      controls: false,
      nav: true,
      items: 1.3,
      loop: true,
      edgePadding: 20,
      preventScrollOnTouch: 'auto',
      gutter: 20,
      responsive: {
        768: {
          items: 3.5,
          edgePadding: 20,
          startIndex: 0,
          mouseDrag: true,
          controls: false,
          gutter: 20
        },
        1025: {
          items: 4,
          edgePadding: 0,
          startIndex: 0,
          mouseDrag: true,
          controls: true,
          gutter: 40
        }
      }
    }

    function handleScreenChange(e) {
      if (e.matches) {
        if (slider?.destroy) {
          slider.destroy()
        }
        if (sliderItemLength < 4) {
          return
        }

        if (!slider) {
          slider = tns(options)
          trackCarouselSwipe(slider, 'inspirationalCasesCarouselSwipe', 1)
        } else if (!slider.isOn) {
          slider = slider.rebuild()
        }
      }
    }
    function handleIsMobileChange(e) {
      if (e.matches) {
        if (slider?.destroy) {
          slider.destroy()
        }
        if (sliderItemLength < 3) {
          return
        }

        if (!slider) {
          slider = tns(options)
          trackCarouselSwipe(slider, 'inspirationalCasesCarouselSwipe', 1)
        } else if (!slider.isOn) {
          slider = slider.rebuild()
        }
      } else {
        if (slider?.destroy) {
          slider.destroy()
        }
        if (!slider) {
          slider = tns(options)
          trackCarouselSwipe(slider, 'inspirationalCasesCarouselSwipe', 1)
        } else if (!slider.isOn) {
          slider = slider.rebuild()
        }
      }
    }
    mediaQuery.addEventListener('change', handleScreenChange)
    handleScreenChange(mediaQuery)
    mediaisMobile.addEventListener('change', handleIsMobileChange)
    handleIsMobileChange(mediaisMobile)

    const links = document.querySelectorAll('[data-track-click="true"]')
    links.forEach((link) => {
      link.addEventListener('click', () => {
        window.dataLayer.push({
          event: link.dataset.trackingEvent,
          sliderType: link.dataset.trackingSliderType,
          sliderTitle: link.dataset.trackingSliderTitle,
          sliderItemTitle: link.dataset.trackingSliderItemTitle
        })
      })
    })
  })
}

const eventsCarousel = () => {
  const sliderEl = Array.from(document.querySelectorAll('.c-events__wrapper'))

  sliderEl.forEach((sliderItem) => {
    let slider
    const options = {
      container: sliderItem,
      controls: false,
      nav: false,
      items: 1.11,
      loop: false,
      edgePadding: 20,
      preventScrollOnTouch: 'auto',
      gutter: 20
    }

    function handleScreenChange(e) {
      if (e.matches) {
        if (slider && slider.isOn) {
          slider.destroy()
        }
      } else if (!slider && sliderItem.children.length > 1) {
        slider = tns(options)
        trackCarouselSwipe(slider, 'eventsCarouselSwipe', 1)
      } else if (!slider.isOn) {
        slider = slider.rebuild()
      }
    }
    mediaQuery.addEventListener('change', handleScreenChange)
    handleScreenChange(mediaQuery)
  })
}

const linkedInFeedCarousel = () => {
  const sliderEl = document.querySelectorAll('.c-linkedin-feed-block__items')
  sliderEl.forEach((sliderItem) => {
    if (!sliderEl.length) {
      return
    }
    const slider = tns({
      container: sliderItem,
      controls: false,
      nav: true,
      items: 1.3,
      loop: false,
      edgePadding: 20,
      preventScrollOnTouch: 'auto',
      gutter: 20,
      responsive: {
        768: {
          items: 2.5,
          edgePadding: 20,
          startIndex: 0,
          mouseDrag: true,
          controls: false,
          gutter: 20
        },
        1025: {
          items: 3,
          edgePadding: 0,
          startIndex: 0,
          mouseDrag: true,
          controls: true,
          gutter: 40
        },
        1200: {
          items: 3.5,
          edgePadding: 0,
          startIndex: 0,
          mouseDrag: true,
          controls: true,
          gutter: 40
        },
        1600: {
          items: 4,
          edgePadding: 0,
          startIndex: 0,
          mouseDrag: true,
          controls: true,
          gutter: 40
        }
      }
    })
  })
}

export { iconCarousel, featuredContentCarousel, eventsCarousel, linkedInFeedCarousel, relatedContentCarousel }
